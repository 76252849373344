import React from 'react';
import { Container, Button, Table } from "react-bootstrap";
import { locale } from './Locale';
import { Helmet } from "react-helmet";

export class DownloadComponent extends React.Component {
    render() {
        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
            <Helmet>
                <title>Anihance - {locale.get("Download")}</title>
            </Helmet>
            <Container fluid="sm" style={{ maxWidth: 600, textAlign: "center" }}>
                <h4>{locale.get("DownloadMinimumRequirements")}</h4>
                <Table size="sm" bordered responsive>
                    <tbody>
                        <tr>
                            <td><b>{locale.get("OperatingSystem")}</b></td>
                            <td>{locale.get("OperatingSystemMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>{locale.get("Processor")}</b></td>
                            <td>{locale.get("ProcessorMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>{locale.get("Memory")}</b></td>
                            <td>{locale.get("MemoryMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>{locale.get("GPU")}</b></td>
                            <td>{locale.get("GPUMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>DirectX</b></td>
                            <td>{locale.get("DirectXMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>{locale.get("Network")}</b></td>
                            <td>{locale.get("NetworkMinimum")}</td>
                        </tr>
                        <tr>
                            <td><b>{locale.get("Storage")}</b></td>
                            <td>100MB</td>
                        </tr>
                    </tbody>
                </Table>
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                    {locale.get("DownloadAlternatives")}
                </div>
                <iframe title="Steam Widget" src="https://store.steampowered.com/widget/1979340/" frameborder="0" width="646" height="190"></iframe>
                {
                // TODO: Add current setup file to the build directory and set the href to the filename here
                }
                <h4>{locale.get("DownloadOR")}</h4>
                <div className="d-grid gap-2">
                    <Button style={{ marginTop: 20 }} variant="primary" size="lg" href="/Anihance v1.2 Setup.exe">
                        {locale.get("DownloadForAnihanceAccount")}
                    </Button>
                </div>
                <h4 style={{ marginTop: 100 }}>{locale.get("VersionHistory")}</h4>
                <div style={{ textAlign: "left" }}>
                    <b>v1.2</b> <small>02.05.2022</small>
                    {locale.get("v1.2Changes")}
                    <b>v1.1</b> <small>29.12.2021</small>
                    {locale.get("v1.1Changes")}
                    <b>v1.0</b> <small>10.06.2021</small>
                    {locale.get("v1.0Changes")}
                    <b>v0.9</b> <small>28.03.2021</small>
                    {locale.get("v0.9Changes")}
                    <b>v0.8</b> <small>23.03.2021</small>
                    {locale.get("v0.8Changes")}
                    <b>v0.7</b> <small>01.01.2021</small>
                    {locale.get("v0.7Changes")}
                    <b>v0.6</b> <small>20.12.2020</small>
                    {locale.get("v0.6Changes")}
                    <b>v0.5</b> <small>16.12.2020</small>
                    {locale.get("v0.5Changes")}
                </div>
            </Container>
            </div>
        );
    }
}