import React from 'react';
import { Container } from "react-bootstrap";

export class MessageComponent extends React.Component {
    render() {
        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center", height: "50vh" }}>
            <Container fluid="sm" style={{ textAlign: "center", maxWidth: 400 }}>
                <h4>{this.props.header}</h4>
                <p>{this.props.message}</p>
                <a href={this.props.link}>{this.props.linkLabel}</a>
            </Container>
            </div>
        );
    }
  }