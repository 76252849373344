import React from 'react';
import { Container } from "react-bootstrap";
import { locale, languageEnum } from './Locale';
import cookie from "react-cookies";
import { Helmet } from "react-helmet";

export class LegalNoticeComponent extends React.Component {
    render() {
        let language = parseInt(cookie.load("Language"));

        let pageNotTranslated = null;
        
        // Show a warning if the page has not been translated yet (or will not be)
        if (language === languageEnum.Korean || language === languageEnum.Japanese) {
            pageNotTranslated = <p style={{textAlign: "center" }}>{locale.get("PageNotTranslated")}</p>;
        }

        if (language === languageEnum.German) {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("LegalNotice")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 700 }}>
                    {pageNotTranslated}
                    <h2>Impressum</h2>
                    <br/>
                    <h3>Angaben gemäß §5 TMG:</h3>
                    <p>Moritz Schöpf</p>
                    <h4>Postanschrift:</h4>
                    <p>Krüner Str. 126<br/>81377 München<br/>Deutschland</p>
                    <h4>Kontakt</h4>
                    <p>E-Mail: moritz.schoepf@anihance.com</p>
                    <h4>Umsatzsteuer-Identifikationsnummer</h4>
                    <p>Umsatzsteuerfrei gemäß §19 UStG.</p>
                    <br/>
                    <h3>Information gemäß §36 VSBG</h3>
                    <p>Gemäß §36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung in Verbrauchersachen) erklärt der Betreiber dieser Website:</p>
                    <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                    <h3>Hinweis</h3>
                    <p>Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie online unter: https://ec.europa.eu/consumers/odr/</p>
                    <p>Das Impressum wurde mit dem Impressums-Generator der <a href="https://www.activemind.de/">activeMind AG</a> erstellt.</p>
                </Container>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("LegalNotice")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 700 }}>
                    {pageNotTranslated}
                    <h2>Legal Notice</h2>
                    <br/>
                    <h3>Information according to §5 TMG:</h3>
                    <p>Moritz Schöpf</p>
                    <h4>Postal Address:</h4>
                    <p>Krüner Str. 126<br/>81377 Munich<br/>Germany</p>
                    <h4>Contact</h4>
                    <p>E-Mail: moritz.schoepf@anihance.com</p>
                    <h4>VAT Identification Number</h4>
                    <p>Free of VAT according to §19 UStG.</p>
                    <br/>
                    <h3>Information according to §36 VSBG</h3>
                    <p>According to §36 VSBG (Consumer Dispute Resolution Act - Act on Alternative Dispute Resolution in Consumer Matters) the operator of this website declares:</p>
                    <p>We are neither willing nor obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>
                    <h3>Note</h3>
                    <p>The EU out-of-court dispute resolution platform is available online at: https://ec.europa.eu/consumers/odr/</p>
                    <p>This imprint was created with the imprint generator of <a href="https://www.activemind.de/">activeMind AG</a>.</p>
                </Container>
                </div>
            );
        }
    }
  }