import cookie from "react-cookies";

export const languageEnum = {
    "English" : 0,
    "German" : 1,
    "Korean" : 2,
    "Japanese" : 3
};

const stringtable = {
    "About" : {
        [languageEnum.English] : "About",
        [languageEnum.German] : "Über",
        [languageEnum.Korean] : "소개",
        [languageEnum.Japanese] : "について"
    },
    "Download" : {
        [languageEnum.English] : "Download",
        [languageEnum.German] : "Herunterladen",
        [languageEnum.Korean] : "다운로드",
        [languageEnum.Japanese] : "ダウンロード"
    },
    "DownloadAlternatives" : {
        [languageEnum.English] : "You can either download Anihance on Steam (requires a Steam account) or you can download it directly for your registered Anihance account.",
        [languageEnum.German] : "Du kannst Anihance entweder auf Steam herunterladen (erfordert ein Steam-Konto) oder du kannst es direkt für dein registriertes Anihance-Konto herunterladen.",
        [languageEnum.Korean] : "Steam에서 Anihance를 다운로드하거나(Steam 계정 필요) 등록된 Anihance 계정에 대해 직접 다운로드할 수 있습니다.",
        [languageEnum.Japanese] : "Steam で Anihance をダウンロードするか (Steam アカウントが必要)、登録済みの Anihance アカウントに直接ダウンロードできます。"
    },
    "DownloadOR" : {
        [languageEnum.English] : "OR",
        [languageEnum.German] : "ODER",
        [languageEnum.Korean] : "또는",
        [languageEnum.Japanese] : "また"
    },
    "DownloadForAnihanceAccount" : {
        [languageEnum.English] : "Download for your Anihance account",
        [languageEnum.German] : "Herunterladen für deinen Anihance-Konto",
        [languageEnum.Korean] : "Anihance 계정에 다운로드",
        [languageEnum.Japanese] : "Anihanceアカウント用にダウンロード"
    },
    "FAQ" : {
        [languageEnum.English] : "FAQ",
        [languageEnum.German] : "FAQ",
        [languageEnum.Korean] : "자주하는 질문",
        [languageEnum.Japanese] : "よくあるご質問"
    },
    "Feedback" : {
        [languageEnum.English] : "Feedback",
        [languageEnum.German] : "Feedback",
        [languageEnum.Korean] : "피드백",
        [languageEnum.Japanese] : "フィードバック"
    },
    "Language" : {
        [languageEnum.English] : "Language",
        [languageEnum.German] : "Sprache",
        [languageEnum.Korean] : "언어",
        [languageEnum.Japanese] : "言語"
    },
    "PageNotTranslated" : {
        [languageEnum.English] : "This page is not available in English.",
        [languageEnum.German] : "Diese Seite ist nicht auf Deutsch verfügbar.",
        [languageEnum.Korean] : "이 페이지는 한국어로 제공되지 않습니다.",
        [languageEnum.Japanese] : "このウェブページは日本語ではご利用いただけません。"
    },
    "Register" : {
        [languageEnum.English] : "Register",
        [languageEnum.German] : "Registrieren",
        [languageEnum.Korean] : "회원가입",
        [languageEnum.Japanese] : "登録"
    },
    "Login" : {
        [languageEnum.English] : "Login",
        [languageEnum.German] : "Anmelden",
        [languageEnum.Korean] : "로그인",
        [languageEnum.Japanese] : "ログイン"
    },
    "Logout" : {
        [languageEnum.English] : "Logout",
        [languageEnum.German] : "Abmelden",
        [languageEnum.Korean] : "로그 아웃",
        [languageEnum.Japanese] : "ログアウト"
    },
    "LicenseAgreement" : {
        [languageEnum.English] : "License Agreement",
        [languageEnum.German] : "Lizenzvereinbarung",
        [languageEnum.Korean] : "라이선스 구매",
        [languageEnum.Japanese] : "ライセンス契約"
    },
    "PrivacyPolicy" : {
        [languageEnum.English] : "Privacy Policy",
        [languageEnum.German] : "Datenschutzerklärung",
        [languageEnum.Korean] : "개인 정보 정책",
        [languageEnum.Japanese] : "プライバシー ポリシー"
    },
    "LegalNotice" : {
        [languageEnum.English] : "Legal Notice",
        [languageEnum.German] : "Impressum",
        [languageEnum.Korean] : "법적 고지",
        [languageEnum.Japanese] : "法律上の注意事項"
    },
    "CookieConsent" : {
        [languageEnum.English] : "This website exclusively uses essential cookies for language localization and the management of your account.",
        [languageEnum.German] : "Diese Webseite benutzt ausschließlich essenzielle Cookies für Spracheinstellungen und die Verwaltung deines Benutzerkontos.",
        [languageEnum.Korean] : "이 웹 사이트는 언어 현지화 및 계정 관리를 위해 필수 쿠키를 독점적으로 사용합니다.",
        [languageEnum.Japanese] : "このウェブサイトは、言語のローカリゼーションとアカウントの管理に不可欠な Cookie のみを使用しています。"
    },
    "CookieConsentAgree" : {
        [languageEnum.English] : "Got it!",
        [languageEnum.German] : "Alles klar!",
        [languageEnum.Korean] : "동의",
        [languageEnum.Japanese] : "了解"
    },
    "Username" : {
        [languageEnum.English] : "Username",
        [languageEnum.German] : "Benutzername",
        [languageEnum.Korean] : "사용자 이름",
        [languageEnum.Japanese] : "ユーザーネーム"
    },
    "Email" : {
        [languageEnum.English] : "E-Mail",
        [languageEnum.German] : "E-Mail",
        [languageEnum.Korean] : "이메일",
        [languageEnum.Japanese] : "Eメール"
    },
    "Password" : {
        [languageEnum.English] : "Password",
        [languageEnum.German] : "Passwort",
        [languageEnum.Korean] : "암호",
        [languageEnum.Japanese] : "パスワード"
    },
    "ResetPassword" : {
        [languageEnum.English] : "Reset password",
        [languageEnum.German] : "Passwort zurücksetzen",
        [languageEnum.Korean] : "암호를 재설정",
        [languageEnum.Japanese] : "パスワードリセット"
    },
    "ResetPasswordNote" : {
        [languageEnum.English] : "We will reset your password and send you an E-Mail with instructions.",
        [languageEnum.German] : "Wir setzen dein Passwort zurück und senden dir eine E-Mail mit Anweisungen.",
        [languageEnum.Korean] : "비밀번호를 재설정하고 지침이 포함 된 이메일을 보내드립니다.",
        [languageEnum.Japanese] : "パスワードをリセットし、手順を記載したメールをお送りします。"
    },
    "ResetPasswordSuccess" : {
        [languageEnum.English] : "Please check your inbox!",
        [languageEnum.German] : "Bitte prüfe deinen Posteingang!",
        [languageEnum.Korean] : "받은 편지함을 확인하십시오!",
        [languageEnum.Japanese] : "インボックスをチェックしてみてください。"
    },
    "ResetPasswordFail" : {
        [languageEnum.English] : "Failed to reset your password. Note: You can only reset your password once per hour.",
        [languageEnum.German] : "Fehler beim Zurücksetzen deines Passworts. Hinweis: Du kannst dein Passwort nur einmal pro Stunde zurücksetzen.",
        [languageEnum.Korean] : "비밀번호를 재설정하지 못했습니다. 참고 : 비밀번호는 1 시간에 한 번만 재설정 할 수 있습니다.",
        [languageEnum.Japanese] : "パスワードのリセットできませんでした。注: パスワードのリセットは1時間に1回しかできません。"
    },
    "UsernameHint" : {
        [languageEnum.English] : "Can contain A-z, 0-9 and special characters.",
        [languageEnum.German] : "Kann A-z, 0-9 und Sonderzeichen enthalten.",
        [languageEnum.Korean] : "A-z, 0-9 및 특수 문자를 포함 할 수 있습니다.",
        [languageEnum.Japanese] : "A〜z、0〜9、および特殊文字を含めることができます。"
    },
    "EmailHint" : {
        [languageEnum.English] : "Needs to be verified after registration.",
        [languageEnum.German] : "Muss nach der Registrierung verifiziert werden.",
        [languageEnum.Korean] : "등록 후 확인이 필요합니다.",
        [languageEnum.Japanese] : "登録後にアカウント検証が必要です。"
    },
    "PasswordHint" : {
        [languageEnum.English] : "Needs to be at least 8 alphanumeric characters long.",
        [languageEnum.German] : "Muss mindestens 8 alphanumerische Zeichen lang sein.",
        [languageEnum.Korean] : "8 자 이상의 영문, 숫자 여야합니다.",
        [languageEnum.Japanese] : "8文字以上の英数字が必要です。"
    },
    "CheckboxAgreeStart" : {
        [languageEnum.English] : "I agree to the ",
        [languageEnum.German] : "Ich stimme der ",
        [languageEnum.Korean] : "",
        [languageEnum.Japanese] : ""
    },
    "CheckboxAgreeMid" : {
        [languageEnum.English] : " and the ",
        [languageEnum.German] : " und der ",
        [languageEnum.Korean] : " 및 ",
        [languageEnum.Japanese] : "と"
    },
    "CheckboxAgreeEnd" : {
        [languageEnum.English] : ".",
        [languageEnum.German] : " zu.",
        [languageEnum.Korean] : "에 동의합니다.",
        [languageEnum.Japanese] : "に同意。"
    },
    "CreateAccount" : {
        [languageEnum.English] : "Create account",
        [languageEnum.German] : "Benutzerkonto erstellen",
        [languageEnum.Korean] : "계정 생성",
        [languageEnum.Japanese] : "アカウントの作成"
    },
    "CreateAccountHint" : {
        [languageEnum.English] : "Here you can create your Anihance account. This step is required to use Anihance. Your 14 day free trial starts immediately with the registration.",
        [languageEnum.German] : "Hier kannst du dein Anihance Benutzerkonto erstellen. Dieser Schritt ist notwendig, um Anihance zu nutzen. Deine 14 Tage lange kostenlose Testversion startet sofort mit der Registrierung.",
        [languageEnum.Korean] : "여기에서 Anihance 계정을 만들 수 있습니다. Anihance를 사용하려면이 단계가 필요합니다. 14 일 무료 평가판은 등록과 동시에 시작됩니다.",
        [languageEnum.Japanese] : "ここではAnihanceのアカウントを作成します。このステップはAnihanceを使用するために必要です。登録後、14日間のフリートレイアルが始まります。"
    },
    "ErrorRegisterFail" : {
        [languageEnum.English] : "Registration failed. Maybe this account already exists or another issue occured. Note: You can only register once per hour.",
        [languageEnum.German] : "Registrierung fehlgeschlagen. Vielleicht existiert dieses Benutzerkonto schon oder ein anderes Problem ist aufgetreten. Hinweis: Du kannst dich nur einmal pro Stunde registrieren.",
        [languageEnum.Korean] : "등록 실패. 이 계정이 이미 존재하거나 다른 문제가 발생했을 수 있습니다. 참고 : 1 시간에 한 번만 등록 할 수 있습니다.",
        [languageEnum.Japanese] : "登録できませんでした。このアカウントはすでに存在しているあか、別のエラーが発生しました。注: 登録は1時間に1回しかできません。"
    },
    "RegisterSuccess" : {
        [languageEnum.English] : "Registration successful!",
        [languageEnum.German] : "Registrierung erfolgreich!",
        [languageEnum.Korean] : "등록 성공!",
        [languageEnum.Japanese] : "登録完了!"
    },
    "VerifyAccount" : {
        [languageEnum.English] : "Please verify your account by clicking on the link in the E-Mail that we sent you. It might be hidden in the advertisements or spam folder.",
        [languageEnum.German] : "Bitte verifiziere dein Benutzerkonto indem du auf den Link in der E-Mail klickst, die wir dir geschickt haben. Die E-Mail könnte auch im Werbung oder Spam Ordner versteckt sein.",
        [languageEnum.Korean] : "보내 드린 이메일에있는 링크를 클릭하여 계정을 확인하십시오. 광고 또는 스팸 폴더에 있을 수 있습니다.",
        [languageEnum.Japanese] : "送信したE-Mailに記載されているリンクをクリックして、アカウントの検証をしてください。広告やスパムフォルダに隠れている可能性があります。"
    },
    "VerifySuccess" : {
        [languageEnum.English] : "Your account is verified.",
        [languageEnum.German] : "Dein Benutzerkonto ist verifiziert.",
        [languageEnum.Korean] : "귀하의 계정이 확인되었습니다.",
        [languageEnum.Japanese] : "アカウント検証終了。"
    },
    "VerifySuccessNote" : {
        [languageEnum.English] : "You can now log in to the Anihance application and website.",
        [languageEnum.German] : "Du kannst dich jetzt in der Anihance Anwendung und Webseite anmelden.",
        [languageEnum.Korean] : "이제 Anihance 애플리케이션 및 웹 사이트에 로그인 할 수 있습니다.",
        [languageEnum.Japanese] : "これでAnihanceのアプリやサイトにログインできるようになりました。"
    },
    "VerifyFail" : {
        [languageEnum.English] : "There was an error when trying to verify your account.",
        [languageEnum.German] : "Bei der Verifizierung deines Benutzerkontos ist ein Fehler aufgetreten.",
        [languageEnum.Korean] : "계정을 확인하는 중에 오류가 발생했습니다.",
        [languageEnum.Japanese] : "アカウント検証中にエラーが発生しました。"
    },
    "LoginFail" : {
        [languageEnum.English] : "Login failed. Maybe the email or password is wrong?",
        [languageEnum.German] : "Anmeldung fehlgeschlagen. Vielleicht ist die E-Mail oder das Passwort falsch?",
        [languageEnum.Korean] : "로그인 실패. 이메일이나 비밀번호를 확인하세요.",
        [languageEnum.Japanese] : "ログインできませんでした。Eメールまたはパスワードが間違っています。 もう一度やり直してください。"
    },
    "License" : {
        [languageEnum.English] : "License",
        [languageEnum.German] : "Lizenz",
        [languageEnum.Korean] : "라이선스",
        [languageEnum.Japanese] : "ライセンス"
    },
    "AnihanceLicense" : {
        [languageEnum.English] : "Anihance Perpetual License",
        [languageEnum.German] : "Anihance Unbefristete Lizenz",
        [languageEnum.Korean] : "Anihance 영구 라이선스",
        [languageEnum.Japanese] : "Anihance 永久ライセンス"
    },
    "YourAccount" : {
        [languageEnum.English] : "Your account",
        [languageEnum.German] : "Dein Benutzerkonto",
        [languageEnum.Korean] : "귀하의 계정",
        [languageEnum.Japanese] : "あなたのアカウント"
    },
    "FreeTrial" : {
        [languageEnum.English] : "FREE TRIAL",
        [languageEnum.German] : "KOSTENLOSE TESTVERSION",
        [languageEnum.Korean] : "무료 시험판",
        [languageEnum.Japanese] : "フリートレアル"
    },
    "DaysLeft" : {
        [languageEnum.English] : "days left",
        [languageEnum.German] : "Tage übrig",
        [languageEnum.Korean] : "남은 일수",
        [languageEnum.Japanese] : "残りの日数"
    },
    "PaidLicense" : {
        [languageEnum.English] : "PERPETUAL",
        [languageEnum.German] : "UNBEFRISTET",
        [languageEnum.Korean] : "부단한",
        [languageEnum.Japanese] : "永久"
    },
    "LicenseExpired" : {
        [languageEnum.English] : "EXPIRED",
        [languageEnum.German] : "ABGELAUFEN",
        [languageEnum.Korean] : "만료 됨",
        [languageEnum.Japanese] : "(ライセンス) 期限切れ"
    },
    "Buy" : {
        [languageEnum.English] : "Buy for 7.99€",
        [languageEnum.German] : "Für 7,99€ kaufen",
        [languageEnum.Korean] : "7.99 €에 구매",
        [languageEnum.Japanese] : "7.99ユーロで購入"
    },
    "BuyNote" : {
        [languageEnum.English] : "Here you can buy your perpetual Anihance License with a one time payment of 7.99€! After paying you will receive the invoice in an E-Mail and your use of Anihance will be unlimited on up to three concurrent Windows 10 devices.",
        [languageEnum.German] : "Hier kannst du deine unbefristete Anihance Lizenz für 7.99€ kaufen! Nach der Zahlung wirst du die Rechnung per E-Mail erhalten und Anihance unbefristet auf bis zu drei Windows 10 Geräten gleichzeitig nutzen können.",
        [languageEnum.Korean] : "여기에서 7.99 €의 일회성 결제로 영구 Anihance 라이선스를 구입할 수 있습니다! 결제 후에는 이메일로 청구서를 받게되며 최대 3 개의 동시 Windows 10 장치에서 Anihance를 무제한으로 사용할 수 있습니다.",
        [languageEnum.Japanese] : "ここで7.99ユーロの一回払いで永久Anihanceライセンスを購入。支払い後、Eメールで請求書をお送りします。Anihanceを3台までのWindows 10デバイスで無制限にご利用いただけます。"
    },
    "DeviceList" : {
        [languageEnum.English] : "Manage devices",
        [languageEnum.German] : "Geräte verwalten",
        [languageEnum.Korean] : "장치 관리",
        [languageEnum.Japanese] : "デバイスマネジメント"
    },
    "DeviceListNote" : {
        [languageEnum.English] : "Here is a list of all the devices where you are logged in to the Anihance application. You can have a maximum of three concurrent devices. If you remove a currently used device you will be able to log in on a new device.",
        [languageEnum.German] : "Hier ist eine Liste der Geräte, bei denen du gerade mit der Anihance Anwendung angemeldet bist. Du kannst maximal drei Geräte gleichzeitig nutzen. Entferne ein aktuelles Gerät, um dich auf einem anderen neuen Gerät anmelden zu können.",
        [languageEnum.Korean] : "다음은 Anihance 애플리케이션에 로그인 한 모든 장치의 목록입니다. 최대 3 개의 동시 장치를 가질 수 있습니다. 현재 사용중인 장치를 제거하면 새 장치에 로그인 할 수 있습니다.",
        [languageEnum.Japanese] : "Anihanceアプリにログインしているデバイスのリストです。最大3台のデバイスを同時に使用することができます。現在使用しているデバイスを削除すると、新しいデバイスでログインできるようになります。"
    },
    "DeviceListEmpty" : {
        [languageEnum.English] : "You don't have any devices yet.",
        [languageEnum.German] : "Du hast noch keine Geräte.",
        [languageEnum.Korean] : "아직 기기가 검색되지 않았습니다.",
        [languageEnum.Japanese] : "まだログインしているデバイスがありません。"
    },
    "RemoveDevice" : {
        [languageEnum.English] : "Remove",
        [languageEnum.German] : "Entfernen",
        [languageEnum.Korean] : "제거",
        [languageEnum.Japanese] : "削除"
    },
    "ChangePassword" : {
        [languageEnum.English] : "Change password",
        [languageEnum.German] : "Passwort ändern",
        [languageEnum.Korean] : "비밀번호 변경",
        [languageEnum.Japanese] : "パスワードの変更"
    },
    "ChangePasswordNote" : {
        [languageEnum.English] : "Changing your password will remove all of your devices. You will need to log in again with the new password.",
        [languageEnum.German] : "Eine Passwortänderung wird alle deine Geräte entfernen. Du wirst dich dann mit dem neuen Passwort einloggen müssen.",
        [languageEnum.Korean] : "암호를 변경하면 모든 장치가 제거됩니다. 새 비밀번호로 다시 로그인해야합니다.",
        [languageEnum.Japanese] : "パスワードを変更すると、すべてのデバイスがログアウトされます。新しいパスワードでログインし直ししてください。"
    },
    "OldPassword" : {
        [languageEnum.English] : "Old password",
        [languageEnum.German] : "Altes Passwort",
        [languageEnum.Korean] : "기존 비밀번호",
        [languageEnum.Japanese] : "古いパスワード"
    },
    "NewPassword" : {
        [languageEnum.English] : "New password",
        [languageEnum.German] : "Neues Passwort",
        [languageEnum.Korean] : "새 비밀번호",
        [languageEnum.Japanese] : "新しいパスワード"
    },
    "ChangePasswordSuccess" : {
        [languageEnum.English] : "Password changed successfully!",
        [languageEnum.German] : "Passwort wurde erfolgreich geändert!",
        [languageEnum.Korean] : "암호가 성공적으로 변경되었습니다!",
        [languageEnum.Japanese] : "パスワード変更完了"
    },
    "ChangePasswordFail" : {
        [languageEnum.English] : "Failed to change the password. Maybe the old password is wrong?",
        [languageEnum.German] : "Passwort konnte nicht geändert werden. Vielleicht ist das alte Passwort falsch?",
        [languageEnum.Korean] : "비밀번호를 변경하지 못했습니다. 이전 암호가 잘못되었을 수 있습니다",
        [languageEnum.Japanese] : "パスワード変更できませんでした。もしかして、古いパスワードが間違っているのでは？"
    },
    "FrequentlyAskedQuestions" : {
        [languageEnum.English] : "Frequently Asked Questions",
        [languageEnum.German] : "Häufig gestellte Fragen",
        [languageEnum.Korean] : "자주 묻는 질문",
        [languageEnum.Japanese] : "よくある質問"
    },
    "FAQBlackVideoQuestion" : {
        [languageEnum.English] : "The video turns black when going fullscreen. What can I do?",
        [languageEnum.German] : "Das Video wird schwarz wenn ich in den Vollbildmodus wechsle. Was kann ich tun?",
        [languageEnum.Korean] : "전체 화면으로 전환하면 비디오가 검게 변합니다. 어떻게 해야 하나요?",
        [languageEnum.Japanese] : "フルスクリーンにするとビデオが黒くなります。どうすればいいですか？"
    },
    "FAQBlackVideoAnswer" : {
        [languageEnum.English] : <div>Disabling hardware acceleration in your browser settings can fix this issue.<br/><b>Chrome:</b> Go to Settings->Advanced->System and disable hardware acceleration.<br/><b>Edge:</b> Go to Settings->System and disable hardware acceleration.<br/><b>Firefox:</b> Go to Settings->General->Performance and disable "Use recommended performance settings " and then disable hardware acceleration.<br/><b>Other:</b> You can try to start your video player or web browser in a sandbox. This can be done with e.g. <a href="https://github.com/sandboxie-plus/Sandboxie">Sandboxie</a>.</div>,
        [languageEnum.German] : <div>Das Deaktivieren von Hardwarebeschleunigung in deinen Browsereinstellungen kann dieses Problem lösen.<br/><b>Chrome:</b> Gehe zu Einstellungen->Erweitert->System und deaktiviere Hardwarebeschleunigung.<br/><b>Edge:</b> Gehe zu Einstellungen->System und deaktiviere Hardwarebeschleunigung.<br/><b>Firefox:</b> Gehe zu Einstellungen->Allgemein->Leistung und deaktiviere "Empfohlene Leistungseinstellungen verwenden" und danach schalte Hardwarebeschleunigung aus.<br/><b>Sonstiges:</b> Du kannst versuchen deinen Videoplayer oder Webbrowser in einer Sandbox zu starten. Dabei kann z.B. <a href="https://github.com/sandboxie-plus/Sandboxie">Sandboxie</a> helfen.</div>,
        [languageEnum.Korean] : <div>브라우저 설정에서 하드웨어 가속을 비활성화하면이 문제를 해결할 수 있습니다.<br/><b>Chrome:</b> 설정-> 고급-> 시스템으로 이동하여 하드웨어 가속을 비활성화하십시오.<br/><b>Edge:</b> 설정-> 시스템으로 이동하여 하드웨어 가속을 비활성화하십시오.<br/><b>Firefox:</b> 설정-> 일반-> 성능으로 이동하여 "권장 성능 설정 사용"을 비활성화 한 다음 하드웨어 가속을 비활성화하십시오.<br/><b>Other:</b> 샌드 박스에서 비디오 플레이어 또는 웹 브라우저를 시작할 수 있습니다. 아래 링크 <a href="https://github.com/sandboxie-plus/Sandboxie">Sandboxie</a>로 할 수 있습니다.</div>,
        [languageEnum.Japanese] : <div>ブラウザの設定でハードウェアアクセラレーションを消すと、この問題を解決することができます。<br/><b>Chrome:</b> 設定→高度な設定→システム、ハードウェアアクセラレーションを消す。<br/><b>Edge:</b> 設定→システム、ハードウェアアクセラレーションを消す。<br/><b>Firefox:</b> 設定→一般設定→パフォーマンス、「推奨されるパフォーマンス設定を使用する」を消して、ハードウェアアクセラレーションを消す。<br/><b>その他:</b> ビデオプレーヤーやウェブブラウザをサンドボックスモードで起動してみることができます。これは <a href="https://github.com/sandboxie-plus/Sandboxie">Sandboxie</a> などで行うことができます。</div>
    },
    "FAQUnsuppordedQuestion" : {
        [languageEnum.English] : "Which applications are not supported by Anihance?",
        [languageEnum.German] : "Welche Anwendungen werden von Anihance nicht unterstützt?",
        [languageEnum.Korean] : "Anihance에서 지원하지 않는 애플리케이션은 무엇입니까?",
        [languageEnum.Japanese] : "Anihanceがサポートしていないアプリ / ビデオプレーヤー は？"
    },
    "FAQTableDescription" : {
        [languageEnum.English] : "Due to technical limitations some applications do not work with Anihance. This is the case when you cannot see the Anihance Logo Animation or the screen becomes black when you start watching a fullscreen video. Here is a list of known unsupported applications and their alternatives.",
        [languageEnum.German] : "Wegen technischen Einschränkungen funktionieren einige Anwendungen nicht mit Anihance. Das ist der Fall, wenn du die Anihance Logo Animation nicht siehst oder dein Bildschirm schwarz wird, wenn du anfängst ein Vollbildvideo zu schauen. Hier ist eine Liste von bekannten Anwendungen, die nicht unterstützt werden und ihre Alternativen.",
        [languageEnum.Korean] : "기술적인 제한으로 인해 일부 응용 프로그램은 Anihance에서 작동하지 않습니다. Anihance 로고 애니메이션을 볼 수 없거나 전체 화면 비디오 시청을 시작하면 화면이 검게 변하는 경우에 해당합니다. 다음은 알려진 지원되지 않는 응용 프로그램 및 그 대안 목록입니다.",
        [languageEnum.Japanese] : "技術的な制限により、Anihanceで動作しないアプリもあります。これは、Anihanceロゴアニメーションが表示されなかったり、フルスクリーンに変えるとビデオが黒くなったりする場合です。サポートされていないアプリとその代替品のリストは以下の通りです。"
    },
    "FAQTableUnsupported" : {
        [languageEnum.English] : "Unsupported Application",
        [languageEnum.German] : "Nicht unterstützte Anwendung",
        [languageEnum.Korean] : "지원되지 않는 애플리케이션",
        [languageEnum.Japanese] : "サポートされていないアプリ"
    },
    "FAQTableAlternative" : {
        [languageEnum.English] : "Supported Alternative",
        [languageEnum.German] : "Unterstützte Alternative",
        [languageEnum.Korean] : "지원되는 대안",
        [languageEnum.Japanese] : "サポートされている代替品"
    },
    "FAQTableQuestion" : {
        [languageEnum.English] : "You found another application that isn't supported? Please let us know at support@anihance.com!",
        [languageEnum.German] : "Du hast eine andere Anwendung gefunden, die nicht unterstützt wird? Bitte schreibe uns unter support@anihance.com!",
        [languageEnum.Korean] : "지원되지 않는 다른 애플리케이션일 경우  support@anihance.com으로 알려주세요!",
        [languageEnum.Japanese] : "サポートされていない他のアプリを見つけましたか？support@anihance.com にお知らせください!"
    },
    "FAQCartoonQuestion" : {
        [languageEnum.English] : "Does Anihance also work for Cartoon?",
        [languageEnum.German] : "Funktioniert Anihance auch bei Zeichentrick?",
        [languageEnum.Korean] : "Anihance는 Cartoon에서도 작동합니까?",
        [languageEnum.Japanese] : "Anihanceはカートゥーンようにも使えますか？"
    },
    "FAQCartoonAnswer" : {
        [languageEnum.English] : "Depends. For some cartoon artstyles it might even work very well. Just try it!",
        [languageEnum.German] : "Kommt auf das Video an. Bei einigen Zeichentrickserien kann es sogar sehr gut funktionieren. Einfach ausprobieren!",
        [languageEnum.Korean] : "때에 따라 다릅니다. 일부 만화 아트 스타일의 경우 매우 잘 작동 할 수도 있습니다. 시도해 보세요!",
        [languageEnum.Japanese] : "場合によります。いくつかのカートゥーンのアートスタイルでは、非常にうまくいくかもしれません。試してみてください！"
    },
    "FAQAdvancedOptionsQuestion" : {
        [languageEnum.English] : "Are there any advanced settings that I can tweak?",
        [languageEnum.German] : "Gibt es fortgeschrittene Einstellungen, die ich ändern kann?",
        [languageEnum.Korean] : "조정할 수있는 고급 설정이 있습니까?",
        [languageEnum.Japanese] : "微調整できる高度な設定はありますか？"
    },
    "FAQAdvancedOptionsAnswer" : {
        [languageEnum.English] : "Yes, there is a Settings.txt file stored in your Documents/Anihance directory. There you can change some of the parameters that affect user interface scale, the pause key and other things.",
        [languageEnum.German] : "Ja, es gibt eine Settings.txt Datei in deinem Dokumente/Anihance Ordner. Hier kannst du Parameter ändern, welche die Größe der Benutzeroberfläche, die Pause Taste und andere Dinge beeinflussen.",
        [languageEnum.Korean] : "예, Documents / Anihance 디렉토리에 Settings.txt 파일이 저장되어 있습니다. 여기에서 사용자 인터페이스 스케일, 일시 중지 키 및 기타 사항에 영향을 미치는 일부 매개 변수를 변경할 수 있습니다.",
        [languageEnum.Japanese] : "はい、Documents/AnihanceディレクトリにSettings.txtファイルがあります。そこでは、ユーザーインターフェイスのスケールや一時停止キーなどに影響を与えるパラメータを変えることができます。"
    },
    "FAQNotFullscreenQuestion" : {
        [languageEnum.English] : "Is it possible to run Anihance also for windows that are not fullscreen?",
        [languageEnum.German] : "Ist es möglich Anihance auch auf Fenster anzuwenden, die nicht im Vollbildmodus sind?",
        [languageEnum.Korean] : "전체 화면이 아닌 창에서도 Anihance를 실행할 수 있습니까?",
        [languageEnum.Japanese] : "フルスクリーンでないウィンドウでもAnihanceを使うことは可能ですか？"
    },
    "FAQNotFullscreenAnswer" : {
        [languageEnum.English] : "Yes, set the \"fullscreenOnly\" parameter in the Settings.txt file to 0 and this will happen. But this is not recommended because it is unstable.",
        [languageEnum.German] : "Ja, setze den \"fullscreenOnly\" Parameter in der Settings.txt Datei auf 0 um das einzustellen. Es wird aber nicht empfohlen, da es instabil ist.",
        [languageEnum.Korean] : "예, Settings.txt 파일의 \"fullscreenOnly\"매개 변수를 0으로 설정하면됩니다. 그러나 이것은 불안정하기 때문에 권장하지 않습니다.",
        [languageEnum.Japanese] : "はい、Settings.txtファイルの \"fullscreenOnly\"パラメータを0に設定する。しかし、これは不安定なのでお勧めできません。"
    },
    "FAQPerformanceDropQuestion" : {
        [languageEnum.English] : "I have experienced a drop in performance after changing my GPU. Is there anything I can do?",
        [languageEnum.German] : "Seitdem ich meine Grafikkarte ausgetauscht habe, ist die Leistung schlechter geworden. Was kann ich machen?",
        [languageEnum.Korean] : "GPU를 변경 한 후 성능이 저하되었습니다. 제가 할 수 있는게 있나요?",
        [languageEnum.Japanese] : "GPUを変えてからパフォーマンスが低下しました。何かできることはありますか？"
    },
    "FAQPerformanceDropAnswer" : {
        [languageEnum.English] : "Reinstall Anihance or set the \"benchmark\" parameter in the Settings.txt file to 1 to automatically assign the best values for the current GPU on the next startup.",
        [languageEnum.German] : "Installiere Anihance neu oder setze den \"benchmark\" Parameter in der Settings.txt Datei auf 1 um automatisch die besten Werte für die aktuelle Grafikkarte beim nächsten Start zuzuordnen.",
        [languageEnum.Korean] : "Anihance를 다시 설치하거나 Settings.txt 파일의 \"benchmark\"매개 변수를 1로 설정하여 다음에 시작할 때 현재 GPU에 가장 적합한 값을 자동으로 할당합니다.",
        [languageEnum.Japanese] : "Anihanceをリ・インストールするか、Settings.txtファイルの \"benchmark\"パラメータを1に設定して、次回の起動時に現在のGPUに最適な値を自動的に割り当てるようにします。"
    },
    "DownloadMinimumRequirements" : {
        [languageEnum.English] : "Minimum System Requirements",
        [languageEnum.German] : "Mindestsystemanforderungen",
        [languageEnum.Korean] : "최소 시스템 요구 사항",
        [languageEnum.Japanese] : "最低限のシステム要件"
    },
    "OperatingSystem" : {
        [languageEnum.English] : "Operating System",
        [languageEnum.German] : "Betriebssystem",
        [languageEnum.Korean] : "운영 체제",
        [languageEnum.Japanese] : "オペレーティングシステム"
    },
    "OperatingSystemMinimum" : {
        [languageEnum.English] : "Windows 10 64-bit Version 1903 or later",
        [languageEnum.German] : "Windows 10 64-bit Version 1903 oder neuer",
        [languageEnum.Korean] : "Windows 10 64 비트 버전 1903 이상",
        [languageEnum.Japanese] : "Windows 10 64ビットバージョン1903以降"
    },
    "Processor" : {
        [languageEnum.English] : "Processor",
        [languageEnum.German] : "Prozessor",
        [languageEnum.Korean] : "프로세서",
        [languageEnum.Japanese] : "プロセッサー"
    },
    "ProcessorMinimum" : {
        [languageEnum.English] : "Intel i5-4690, AMD Ryzen 3 3200G or better",
        [languageEnum.German] : "Intel i5-4690, AMD Ryzen 3 3200G oder besser",
        [languageEnum.Korean] : "Intel i5-4690, AMD Ryzen 3 3200G 이상",
        [languageEnum.Japanese] : "Intel i5-4690, AMD Ryzen 3 3200G以上"
    },
    "Memory" : {
        [languageEnum.English] : "Memory",
        [languageEnum.German] : "Arbeitsspeicher",
        [languageEnum.Korean] : "메모리",
        [languageEnum.Japanese] : "メモリ"
    },
    "MemoryMinimum" : {
        [languageEnum.English] : "4GB or more",
        [languageEnum.German] : "4GB oder mehr",
        [languageEnum.Korean] : "4GB 이상",
        [languageEnum.Japanese] : "4GB以上"
    },
    "GPU" : {
        [languageEnum.English] : "GPU",
        [languageEnum.German] : "Grafikkarte",
        [languageEnum.Korean] : "GPU",
        [languageEnum.Japanese] : "GPU"
    },
    "GPUMinimum" : {
        [languageEnum.English] : "Nvidia GTX 970, AMD Radeon RX 480 or better",
        [languageEnum.German] : "Nvidia GTX 970, AMD Radeon RX 480 oder besser",
        [languageEnum.Korean] : "Nvidia GTX 970, AMD Radeon RX 480 이상",
        [languageEnum.Japanese] : "Nvidia GTX 970, AMD Radeon RX 480以上"
    },
    "DirectXMinimum" : {
        [languageEnum.English] : "Version 12 with Shader Model 5.1",
        [languageEnum.German] : "Version 12 mit Shader Model 5.1",
        [languageEnum.Korean] : "Shader Model 5.1이 포함 된 버전 12",
        [languageEnum.Japanese] : "バージョン12（シェーダモデル5.1付き)"
    },
    "Storage" : {
        [languageEnum.English] : "Storage",
        [languageEnum.German] : "Speicherplatz",
        [languageEnum.Korean] : "저장",
        [languageEnum.Japanese] : "ストレージ"
    },
    "Network" : {
        [languageEnum.English] : "Network",
        [languageEnum.German] : "Netzwerk",
        [languageEnum.Korean] : "회로망",
        [languageEnum.Japanese] : "ネットワーク"
    },
    "NetworkMinimum" : {
        [languageEnum.English] : "Internet connection",
        [languageEnum.German] : "Internetverbindung",
        [languageEnum.Korean] : "인터넷 연결",
        [languageEnum.Japanese] : "インターネット接続"
    },
    "Note" : {
        [languageEnum.English] : "Note",
        [languageEnum.German] : "Hinweis",
        [languageEnum.Korean] : "노트",
        [languageEnum.Japanese] : "注"
    },
    "VersionHistory" : {
        [languageEnum.English] : "Version History",
        [languageEnum.German] : "Versionsverlauf",
        [languageEnum.Korean] : "버전 기록",
        [languageEnum.Japanese] : "バージョンヒストリー"
    },
    "v0.5Changes" : {
        [languageEnum.English] : <ul><li>Beta version</li></ul>,
        [languageEnum.German] : <ul><li>Betaversion</li></ul>,
        [languageEnum.Korean] : <ul><li>베타 버전</li></ul>,
        [languageEnum.Japanese] : <ul><li>ベータバージョン</li></ul>
    },
    "v0.6Changes" : {
        [languageEnum.English] : <ul><li>Updated example images</li></ul>,
        [languageEnum.German] : <ul><li>Aktualisierung der Beispielbilder</li></ul>,
        [languageEnum.Korean] : <ul><li>업데이트 된 예제 이미지</li></ul>,
        [languageEnum.Japanese] : <ul><li>サンプル画像の更新。</li></ul>
    },
    "v0.7Changes" : {
        [languageEnum.English] : <ul><li>Improved all quality and intensity levels</li><li>Enhancement now more visible for blurry anime videos</li><li>Removed intensity level 4</li></ul>,
        [languageEnum.German] : <ul><li>Verbesserung aller Qualitäts- und Intensitätsstufen</li><li>Unscharfe Animevideos werden jetzt deutlicher verbessert</li><li>Intensitätsstufe 4 entfernt</li></ul>,
        [languageEnum.Korean] : <ul><li>모든 품질 및 강도 수준 향상</li><li>흐릿한 애니메이션 비디오가 향상된 기능으로 더 잘 보입니다.</li><li>강도 레벨 4 제거</li></ul>,
        [languageEnum.Japanese] : <ul><li>すべての画質と強度のレベルを向上</li><li>ぼやけたアニメも見やすくなりました。</li><li>強度レベル4を削除</li></ul>
    },
    "v0.8Changes" : {
        [languageEnum.English] : <ul><li>All quality and intensity levels now generate detail</li><li>Fixed issues where the application would crash or hang</li><li>Added language support for Korean and Japanese (incomplete)</li><li>Updated example images</li></ul>,
        [languageEnum.German] : <ul><li>Alle Qualitäts- und Intensitätsstufen generieren jetzt Detail</li><li>Behebung von Fehlern bei denen die Anwendung abstürzte oder hängen blieb</li><li>Sprachunterstützung für Koreanisch und Japanisch (unvollständig)</li><li>Aktualisierung der Beispielbilder</li></ul>,
        [languageEnum.Korean] : <ul><li>이제 모든 품질 및 강도 수준이 세부 정보를 생성합니다</li><li>응용 프로그램이 충돌하거나 중단되는 문제를 수정했습니다</li><li>한국어 및 일본어에 대한 언어 지원 추가 (불완전)</li><li>업데이트 된 예제 이미지</li></ul>,
        [languageEnum.Japanese] : <ul><li>すべての品質と強度レベルでディテールを生成するようになりました</li><li>アプリケーションがクラッシュしたり、ハングアップしたりする問題を修正</li><li>韓国語と日本語の言語サポートを追加（未完成)</li><li>サンプル画像の更新</li></ul>,
    },
    "v0.9Changes" : {
        [languageEnum.English] : <ul><li>Improved japanese translation</li></ul>,
        [languageEnum.German] : <ul><li>Verbesserung der japanischen Übersetzung</li></ul>,
        [languageEnum.Korean] : <ul><li>향상된 일본어 번역</li></ul>,
        [languageEnum.Japanese] : <ul><li>日本語訳の改善</li></ul>
    },
    "v1.0Changes" : {
        [languageEnum.English] : <ul><li>Upgraded neural network, improves all quality and intensity levels</li><li>Fixed issue that in some cases RTX tensor cores were not utilized</li><li>Showing input and output resolutions in the control GUI</li><li>Improved energy efficiency by no longer enhancing unchanged video frames</li><li>Updated example images</li><li>Signed executable</li></ul>,
        [languageEnum.German] : <ul><li>Neuronales Netzwerk und alle Qualitäts- und Intensitätsstufen wurden verbessert</li><li>Behebung eines Fehlers, bei dem manchmal RTX Tensor Cores nicht ausgenutzt wurden</li><li>Die GUI Steuerung zeigt nun auch die Ein- und Ausgabeauflösung an</li><li>Verbesserte Energieeffizienz (sich nicht ändernde Frames werden nicht erneut verbessert)</li><li>Aktualisierung der Beispielbilder</li><li>Programmcode wurde signiert</li></ul>,
        [languageEnum.Korean] : <ul><li>업그레이드 된 신경망, 모든 품질 및 강도 수준 향상</li><li>일부 경우 RTX 텐서 코어가 사용되지 않는 문제를 수정했습니다.</li><li>제어 GUI에서 입력 및 출력 해상도 표시</li><li>변경되지 않은 비디오 프레임을 더 이상 향상시키지 않음으로써 에너지 효율성 향상</li><li>업데이트 된 예제 이미지</li><li>서명 된 실행 파일</li></ul>,
        [languageEnum.Japanese] : <ul><li>アップグレードされたニューラル ネットワーク、すべての品質と強度レベルを向上</li><li>場合によっては RTX テンソル コアが利用されない問題を修正しました。</li><li>コントロール GUI での入力解像度と出力解像度の表示</li><li>変更されていないビデオ フレームを強化しないことによるエネルギー効率の向上</li><li>更新されたサンプル画像</li><li>署名された実行可能ファイル</li></ul>,
    },
    "v1.1Changes" : {
        [languageEnum.English] : <ul><li>Removed intensity slider, the AI now automatically detects the intensity level</li><li>Image is now sharper in scenes with mixed compression</li><li>Added motion estimation feature that improves temporal stability on supported systems</li><li>Added detect margins feature that automatically crops away black video frames and improves performance on widescreen displays</li><li>Added toggle for resolution mode</li><li>Fixed a bug when performing the benchmark that caused incorrect settings</li><li>Added "additionalEvaluationCount" parameter to Settings.txt that allows the enhancement to be performed multiple times</li><li>More bug fixes and small changes</li></ul>,
        [languageEnum.German] : <ul><li>Intensitätsschieberegler entfernt, die KI erkennt jetzt automatisch die Intensitätsstufe</li><li>Das Bild ist jetzt in Szenen mit gemischter Komprimierung schärfer</li><li>Bewegungsschätzungsfunktion hinzugefügt, die die zeitliche Stabilität auf unterstützten Systemen verbessert</li><li>Funktion zur Erkennung von Rändern hinzugefügt, die automatisch schwarze Videoframes abschneidet und die Leistung auf Widescreen-Displays verbessert.</li><li>Umschalter für Auflösungsmodus hinzugefügt</li><li>Fehler beim Durchführen des Benchmarks behoben, der zu falschen Einstellungen führte</li><li>Parameter "additionalEvaluationCount" zu Settings.txt hinzugefügt, mit dem die Verbesserung mehrmals durchgeführt werden kann</li><li>Weitere Fehlerbehebungen und kleine Änderungen</li></ul>,
        [languageEnum.Korean] : <ul><li>제거된 강도 슬라이더, 이제 AI가 자동으로 강도 수준을 감지합니다.</li><li>이제 혼합 압축을 사용하는 장면에서 이미지가 더 선명해집니다.</li><li>지원되는 시스템에서 시간적 안정성을 향상시키는 모션 추정 기능 추가</li><li>검은색 비디오 프레임을 자동으로 잘라내고 와이드스크린 디스플레이의 성능을 향상시키는 여백 감지 기능이 추가되었습니다.</li><li>해상도 모드에 대한 토글 추가</li><li>잘못된 설정을 유발하는 벤치마크 수행 시 버그 수정</li><li>향상을 여러 번 수행할 수 있도록 하는 "additionalEvaluationCount" 매개변수를 Settings.txt에 추가했습니다.</li><li>더 많은 버그 수정 및 작은 변경 사항</li></ul>,
        [languageEnum.Japanese] : <ul><li>強度スライダーを削除し、AIが強度レベルを自動的に検出するようになりました</li><li>混合圧縮のシーンで画像がより鮮明になりました</li><li>サポートされているシステムの時間的安定性を向上させるモーションエスティメーション機能が追加されました</li><li>黒いビデオフレームを自動的に切り取り、ワイドスクリーンディスプレイのパフォーマンスを向上させるマージン検出機能が追加されました</li><li>解像度モードのトグルを追加</li><li>誤った設定を引き起こすベンチマークを実行する際のバグを修正しました</li><li>Settings.txtに「additionalEvaluationCount」パラメーターを追加して、拡張を複数回実行できるようにしました</li><li>その他のバグ修正と小さな変更</li></ul>,
    },
    "v1.2Changes" : {
        [languageEnum.English] : <ul><li>Fixed a bug that caused the application to crash on startup with certain graphics cards</li></ul>,
        [languageEnum.German] : <ul><li>Ein Fehler wurde behoben, der dazu führte, dass die Anwendung beim Start mit bestimmten Grafikkarten abstürzte</li></ul>,
        [languageEnum.Korean] : <ul><li>특정 그래픽 카드로 시작할 때 응용 프로그램이 충돌하는 버그를 수정했습니다</li></ul>,
        [languageEnum.Japanese] : <ul><li>特定のグラフィックカードで起動時にアプリケーションがクラッシュする原因となっていたバグを修正しました</li></ul>,
    },
    "PaymentCancel" : {
        [languageEnum.English] : "Payment was canceled. Reload the page to try again.",
        [languageEnum.German] : "Zahlung wurde abgebrochen. Lade die Seite neu um es nochmal zu versuchen.",
        [languageEnum.Korean] : "결제가 취소되었습니다. 다시 시도하려면 페이지를 새로 고침하세요.",
        [languageEnum.Japanese] : "支払いがキャンセルされました。ページをリロードして再度お試しください。"
    },
    "PaymentError" : {
        [languageEnum.English] : "Payment error occured.",
        [languageEnum.German] : "Fehler bei der Bezahlung.",
        [languageEnum.Korean] : "결제 오류가 발생했습니다.",
        [languageEnum.Japanese] : "支払いエラーが発生しました。"
    },
    "PaymentApprove" : {
        [languageEnum.English] : "Payment successful. Thank you!",
        [languageEnum.German] : "Zahlung erfolgreich. Vielen Dank!",
        [languageEnum.Korean] : "결제가 완료되었습니다. 감사합니다!",
        [languageEnum.Japanese] : "支払いが完了しました。ありがとうございした！"
    },
    "PaymentWait" : {
        [languageEnum.English] : "Please wait...",
        [languageEnum.German] : "Bitte warten...",
        [languageEnum.Korean] : "잠시만 기다려주세요 ...",
        [languageEnum.Japanese] : "お待ちください..."
    },
    "LicenseApproveError" : {
        [languageEnum.English] : "An error occurred when trying to validate your license. Please contact support@anihance.com.",
        [languageEnum.German] : "Bei der Verifizierung deiner Lizenz ist ein Fehler aufgetreten. Bitte wende dich an support@anihance.com.",
        [languageEnum.Korean] : "라이선스를 확인하는 중에 오류가 발생했습니다. support@anihance.com으로 문의하십시오.",
        [languageEnum.Japanese] : "ライセンスの検証中にエラーが発生しました。support@anihance.com にご連絡ください。"
    },
    "FeedbackNote" : {
        [languageEnum.English] : "Here you can send us a feedback message about Anihance. If you want to be contacted by us, please also state your E-Mail.",
        [languageEnum.German] : "Hier kannst du uns eine Nachricht mit Feedback zu Anihance schicken. Du kannst auch deine E-Mail angeben, wenn du von uns kontaktiert werden willst.",
        [languageEnum.Korean] : "여기에서 Anihance에 대한 피드백 메시지를 보낼 수 있습니다. 저희에게 연락을 받고 싶으시면 이메일을 말씀해주십시오.",
        [languageEnum.Japanese] : "ここでは、Anihanceについてのフィードバックメッセージを送信することができます。こちらから連絡を取りたい場合は、Eメエルを明記してください。"
    },
    "EmailOptional" : {
        [languageEnum.English] : "E-Mail (optional)",
        [languageEnum.German] : "E-Mail (optional)",
        [languageEnum.Korean] : "이메일 (선택 사항)",
        [languageEnum.Japanese] : "Eメール (オプショナル)"
    },
    "Message" : {
        [languageEnum.English] : "Message",
        [languageEnum.German] : "Nachricht",
        [languageEnum.Korean] : "메시지",
        [languageEnum.Japanese] : "メッセージ"
    },
    "SendFeedback" : {
        [languageEnum.English] : "Send message",
        [languageEnum.German] : "Nachricht senden",
        [languageEnum.Korean] : "메시지 보내기",
        [languageEnum.Japanese] : "メッセージを送る"
    },
    "FeedbackSuccess" : {
        [languageEnum.English] : "Message sent successfully!",
        [languageEnum.German] : "Nachricht erfolgreich gesendet!",
        [languageEnum.Korean] : "메세지가 성공적으로 전송되었습니다!",
        [languageEnum.Japanese] : "メッセージ送信されました"
    },
    "FeedbackSuccessNote" : {
        [languageEnum.English] : "Thank you for your feedback!",
        [languageEnum.German] : "Danke für dein Feedback!",
        [languageEnum.Korean] : "의견을 보내 주셔서 감사합니다!",
        [languageEnum.Japanese] : "フィードバックをありがとうございました！"
    },
    "FeedbackFail" : {
        [languageEnum.English] : "Failed to send feedback message. Note: You can only send feedback once per hour.",
        [languageEnum.German] : "Fehler beim Versenden der Nachricht. Hinweis: Du kannst nur einmal pro Stunde Feedback senden.",
        [languageEnum.Korean] : "피드백 메시지를 보내지 못했습니다. 참고 : 피드백은 1 시간에 한 번만 보낼 수 있습니다.",
        [languageEnum.Japanese] : "フィードバックメッセージ送信できませんでした。注: フィードバックは1時間に1回しか送信できません。"
    },
    "HomeWhatIsAnihance" : {
        [languageEnum.English] : "Enhance your Anime with A.I.",
        [languageEnum.German] : "Verbessere dein Anime mit K.I.",
        [languageEnum.Korean] : "A.I.로 애니메이션의 화질을 향상하세요",
        [languageEnum.Japanese] : "A.I.でアニメの画質を向上"
    },
    "HomeWhatIsAnihanceNote" : {
        [languageEnum.English] : "The Anihance software improves the video quality of anime in real time while you watch the video in a web browser or video player. You only need a powerful Windows 10 PC with a good GPU to start using Anihance today!",
        [languageEnum.German] : "Die Anihance Software verbessert die Videoqualität von Anime in Echtzeit während du das Video in einem Webbrowser oder Videoplayer anschaust. Du brauchst nur einen schnellen Windows 10 PC mit einer guten Grafikkarte um Anihance zu nutzen!",
        [languageEnum.Korean] : "Anihance 소프트웨어는 웹 브라우저 또는 비디오 플레이어에서 비디오를 보는 동안 실시간으로 애니메이션의 비디오 품질을 향상시킵니다. 오늘 Anihance를 사용하려면 우수한 GPU가 장착 된 강력한 Windows 10 PC 만 있으면됩니다!",
        [languageEnum.Japanese] : "Anihanceソフトは、Webブラウザやビデオプレーヤーでアニメを見ながら、リアルタイムでアニメの画質を向上させます。今日からAnihanceの使用を始めるためには、いいGPUを搭載した強力なWindows 10 PCが必要です。"
    },
    "HomeWhatDoesItDo" : {
        [languageEnum.English] : "Enjoy high resolution video",
        [languageEnum.German] : "Genieße hochauflösendes video",
        [languageEnum.Korean] : "고해상도 비디오 즐기기",
        [languageEnum.Japanese] : "高解像度の動画を楽しむ"
    },
    "HomeWhatDoesItDoNote" : {
        [languageEnum.English] : "With Anihance detail is added, lines and edges look sharper, colors are more intense, contrast is improved and the video looks less noisy. You can see for yourself in the interactive comparison further below!",
        [languageEnum.German] : "Mit Anihance wird Detail hinzugefügt, Linien und Kanten werden schärfer, die Farben sind intensiver, der Kontrast ist besser und das Video sieht nicht so verrauscht aus. Du kannst es weiter unten im interaktiven Vergleich selbst ausprobieren!",
        [languageEnum.Korean] : "Anihance 디테일이 추가되면 선과 가장자리가 더 선명 해지고 색상이 더 강렬 해지며 대비가 개선되고 비디오의 노이즈가 줄어 듭니다. 아래의 대화식 비교에서 직접 확인할 수 있습니다!",
        [languageEnum.Japanese] : "Anihanceを使用すると、ディテールが追加され、ラインやエッジがよりシャープに見え、色がより明らかになり、コントラストが改善され、ビデオのノイズが少なくなります。下記のインタラクティブな比較で試してみてください。"
    },
    "HomeUseCases" : {
        [languageEnum.English] : "Artificial Intelligence",
        [languageEnum.German] : "Künstliche Intelligenz",
        [languageEnum.Korean] : "인공 지능",
        [languageEnum.Japanese] : "A.I."
    },
    "HomeUseCasesNote" : {
        [languageEnum.English] : "The Anihance A.I. can upscale and greatly improve image quality for old Anime videos. But it also enhances recent Anime releases! There are several quality levels in the application that you can tweak to your liking.",
        [languageEnum.German] : "Die Anihance K.I. kann alte Animevideos hochskalieren und die Bildqualität stark verbessern. Aber auch neu veröffentlichte Animes werden verbessert! Es gibt unterschliedliche Qualitätsstufen, die du in der Anwendung nach deinem Geschmack einstellen kannst.",
        [languageEnum.Korean] : "Anihance A.I. 오래된 애니메이션 비디오의 이미지 품질을 크게 향상시킬 수 있습니다. 그러나 그것은 또한 최근의 애니메이션 릴리스를 향상시킵니다! 응용 프로그램에는 원하는 대로 조정할 수 있는 여러 품질 수준이 있습니다.",
        [languageEnum.Japanese] : "Anihance A.I. 古いアニメビデオの画質をアップスケールして大幅に向上させることができます。 しかし、それはまた最近のアニメリリースを強化します！ アプリケーションには、好みに合わせて調整できるいくつかの品質レベルがあります。"
    },
    "HomeHowDoesItWork" : {
        [languageEnum.English] : "Easy to use",
        [languageEnum.German] : "Einfache Bedienung",
        [languageEnum.Korean] : "조작이 용이합니다",
        [languageEnum.Japanese] : "使いやすい"
    },
    "HomeHowDoesItWorkNote" : {
        [languageEnum.English] : "Simply watch a fullscreen Anime and see the video enhancement on your screen! Anihance uses highly efficient and optimized artificial neural networks to perform the quality enhancement. This makes sure that it runs in real time while also achieving the best possible visual quality.",
        [languageEnum.German] : "Schau dir einfach ein Anime im Vollbildmodus an und die Videoqualität wird sofort auf deinem Bildschirm verbessert! Anihance benutzt sehr effiziente und optimierte künstliche neuronale Netze um die Qualitätsverbesserung durchzuführen. Dadurch wird in Echtzeit die bestmögliche visuelle Qualität erreicht.",
        [languageEnum.Korean] : "전체 화면으로 애니메이션을보세요. 화면으로 비디오의 품질 향상을 확인하십시오! Anihance는 매우 효율적이고 최적화 된 인공 신경망을 사용하여 품질 향상을 수행합니다. 이를 통해 실시간으로 실행되는 동시에 최상의 시각적 품질을 얻을 수 있습니다.",
        [languageEnum.Japanese] : "アニメをフルスクリーンで見るだけで、画質がエンハンスされます。 Anihanceは、高効率で最適化された人工ニューラルネットワークを使用して画質の向上を実行します。そのA.Iは、.可能な限り最高の画質を実現しながら、リアルタイムで動作するための確認をます。"
    },
    "HomeHowMuchIsIt" : {
        [languageEnum.English] : "It's worth it",
        [languageEnum.German] : "Es lohnt sich",
        [languageEnum.Korean] : "그것은 가치",
        [languageEnum.Japanese] : "価値がありますよ"
    },
    "HomeHowMuchIsItNote" : {
        [languageEnum.English] : "You can use Anihance for free during your 14 day trial period. After that you can buy a license for 7.99€ to keep enjoying enhanced anime on up to three concurrent Windows 10 devices.",
        [languageEnum.German] : "Du kannst Anihance 14 Tage lang kostenlos testen. Danach kannst du eine Lizenz für 7,99€ kaufen und Anihance weiterhin auf bis zu drei Windows 10 Geräten gleichzeitig nutzen.",
        [languageEnum.Korean] : "14 일 평가판 기간 동안 Anihance를 무료로 사용할 수 있습니다. 그런 다음 최대 3 개의 동시 Windows 10 장치에서 향상된 애니메이션을 계속 즐길 수 있도록 7.99 €에 라이선스를 구매할 수 있습니다.",
        [languageEnum.Japanese] : "Anihanceは14日間のお試しは無料でお使いいただけます。その後、7.99€でライセンスを購入して、最大3台のWindows 10デバイスでエンハンスされたアニメを楽しみ続けることができます。"
    },
    "HomeBefore" : {
        [languageEnum.English] : "Before",
        [languageEnum.German] : "Vorher",
        [languageEnum.Korean] : "전에",
        [languageEnum.Japanese] : "前"
    },
    "HomeAfter" : {
        [languageEnum.English] : "After",
        [languageEnum.German] : "Nachher",
        [languageEnum.Korean] : "후",
        [languageEnum.Japanese] : "後"
    },
    "HomeComparison" : {
        [languageEnum.English] : "Interactive comparison",
        [languageEnum.German] : "Interaktiver Vergleich",
        [languageEnum.Korean] : "대화 형 비교",
        [languageEnum.Japanese] : "インタラクティブな比較"
    },
    "HomeComparisonNote" : {
        [languageEnum.English] : "Move the slider below to compare the image before and after using Anihance!",
        [languageEnum.German] : "Verschiebe den Regler um das Bild vor und nach Anihance zu vergleichen!",
        [languageEnum.Korean] : "Anihance를 사용하기 전후의 이미지를 비교하려면 아래 슬라이더를 이동하십시오!",
        [languageEnum.Japanese] : "下のスライダーを動かして、AnihanceないとAnihance使った後の画像を比較できます。"
    },
    "HomeQuestions" : {
        [languageEnum.English] : "Any questions?",
        [languageEnum.German] : "Noch Fragen?",
        [languageEnum.Korean] : "질문 있어요?",
        [languageEnum.Japanese] : "まだ質問がありますか？"
    },
    "HomeQuestionsNote" : {
        [languageEnum.English] : <h5>Maybe your question has already been answered in the <a href="/faq">FAQ</a>? Also you can use the <a href="/feedback">Feedback</a> form to send us ideas about improving Anihance. For other urgent questions support@anihance.com can help.</h5>,
        [languageEnum.German] : <h5>Vielleicht wurde deine Frage schon im <a href="/faq">FAQ</a> beantwortet? Du kannst auch das <a href="/feedback">Feedback</a> Formular benutzen, um uns Verbesserungsvorschläge zu senden. Bei anderen dringenden Fragen kann support@anihance.com helfen.</h5>,
        [languageEnum.Korean] : <h5><a href="/faq">자주하는 질문</a> 에서 이미 질문에 대한 답변을 확인해보세요. 또는  <a href="/feedback">피드백</a>양식을 사용하여 Anihance 개선에 대한 아이디어를 보낼 수 있습니다. 기타 긴급한 질문에 대해서는 support@anihance.com이 도움을 드릴 수 있습니다.</h5>,
        [languageEnum.Japanese] : <h5>もしかしたら、あなたの質問は<a href="/faq">よくあるご質問</a>ですでに回答されているかもしれませんです。または、<a href="/feedback">フィードバック</a>フォームを使って、Anihanceの改善についてのアイデアを送信こともできます。その他のお急ぎの質問は support@anihance.com をご覧ください。</h5>
    },
    "TryForFree" : {
        [languageEnum.English] : "Register and try for free",
        [languageEnum.German] : "Registrieren und kostenlos testen",
        [languageEnum.Korean] : "등록하고 무료로 사용해보기",
        [languageEnum.Japanese] : "登録して無料で試す"
    },
    "DownloadAnihance" : {
        [languageEnum.English] : "Download Anihance",
        [languageEnum.German] : "Anihance herunterladen",
        [languageEnum.Korean] : "Anihance 다운로드",
        [languageEnum.Japanese] : "Anihanceのダウンロード"
    },
    "ErrorPageNonexistent" : {
        [languageEnum.English] : "Error: The requested page does not exist on the server!",
        [languageEnum.German] : "Fehler: Diese gesuchte Seite existiert nicht auf dem Server!",
        [languageEnum.Korean] : "오류 : 요청한 페이지가 서버에 없습니다!",
        [languageEnum.Japanese] : "エラーが発生しました。要求されたページがサーバー上にありません!"
    },
    "ErrorRequest" : {
        [languageEnum.English] : "Error: Request failed!",
        [languageEnum.German] : "Fehler: Anfrage fehlgeschlagen!",
        [languageEnum.Korean] : "오류 : 요청이 실패했습니다!",
        [languageEnum.Japanese] : "エラーが発生しました。リクエストに失敗しました。"
    },
    "FilenameScreenshotControl" : {
        [languageEnum.English] : "./ScreenshotControlEN.png",
        [languageEnum.German] : "./ScreenshotControlDE.png",
        [languageEnum.Korean] : "./ScreenshotControlKR.png",
        [languageEnum.Japanese] : "./ScreenshotControlJP.png"
    },
};

class Locale {
    constructor() {
        // Create a new language cookie if not present
        // Determine which language to use based on the IP related country code
        if (cookie.load("Language") === undefined) {
            fetch("https://ipapi.co/json")
            .then(response => response.json())
            .then(responseJson => {
                console.log(responseJson);
                if (responseJson.country_code === "DE") {
                    this.setLanguage(languageEnum.German);
                } else if (responseJson.country_code === "KR") {
                    this.setLanguage(languageEnum.Korean);
                } else if (responseJson.country_code === "JP") {
                    this.setLanguage(languageEnum.Japanese);
                } else {
                    this.setLanguage(languageEnum.English);
                }
            }).catch(error => {
                this.setLanguage(languageEnum.English);
            });
        }
    }

    get(stringName) {
        let language = cookie.load("Language");
        return stringtable[stringName][language];
    }

    setLanguage(language) {
        let now = new Date();
        let nextYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        cookie.save("Language", language, { path : "/", expires : nextYear });

        // Reload the page to update all the component text
        window.location.reload();
    }
}

export var locale = new Locale();