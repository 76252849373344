import React from 'react';
import { Form, Button, Alert } from "react-bootstrap";
import { locale } from "./Locale";

export class ChangePasswordComponent extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            oldPassword: "",
            oldPasswordValid: false,
            newPassword: "",
            newPasswordValid: false,
            showChangePasswordFailAlert: false,
            showSuccessMessage: false,
            submitButtonDisabled: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        // Validate and update old password input
        if (event.target.id === "oldPassword" && event.target.value.length < 64) {
            this.data.oldPassword = event.target.value.replace(/\s/g, '');
            this.data.oldPasswordValid = this.data.oldPassword.match(/^[\x21-\x7E]{8,64}$/) !== null;
        }

        // Validate and update new password input
        if (event.target.id === "newPassword" && event.target.value.length < 64) {
            this.data.newPassword = event.target.value.replace(/\s/g, '');
            this.data.newPasswordValid = this.data.newPassword.match(/^[\x21-\x7E]{8,64}$/) !== null;
        }
        
        // Check if all the input is valid
        this.data.submitButtonDisabled = !this.data.oldPasswordValid || !this.data.newPasswordValid;
        this.setState(this.data);
    }

    handleSubmit(event) {
        let parameters = new FormData();
        parameters.append("Email", sessionStorage.getItem("Email"));
        parameters.append("OldPassword", this.data.oldPassword);
        parameters.append("NewPassword", this.data.newPassword);

        // Call the PHP script
        fetch("https://api.anihance.com/change-password.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success === true) {
                this.data.showSuccessMessage = true;
                sessionStorage.setItem("Password", this.data.newPassword);
            } else {
                this.data.showChangePasswordFailAlert = true;
            }
            this.setState(this.data);
        }).catch(error => {
            this.data.showChangePasswordFailAlert = true;
            this.setState(this.data);
        });

        event.preventDefault();
    }

    render() {
        if (this.data.showSuccessMessage) {
            return (
            <h2 style={{ textAlign: "center", paddingTop: 80 }}>{locale.get("ChangePasswordSuccess")}</h2>
            );
        }

        let changePasswordFailAlert = null;

        if (this.data.showChangePasswordFailAlert) {
            changePasswordFailAlert = (
                <Alert style={{ marginTop: 20 }} variant="danger" onClick={() => { this.data.showChangePasswordFailAlert = false; this.setState(this.data); }} dismissible>
                    { locale.get("ChangePasswordFail") }
                </Alert>
            );
        }

        return (
            <div>
                <h2 style={{ textAlign: "center" }}>{locale.get("ChangePassword")}</h2>
                {locale.get("ChangePasswordNote")}
                <Form style={{ paddingTop: 20 }} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="oldPassword">
                        <Form.Label>{locale.get("OldPassword")}</Form.Label>
                        <Form.Control type="password" placeholder={locale.get("OldPassword")} value={this.data.oldPassword} onChange={this.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label>{locale.get("NewPassword")}</Form.Label>
                        <Form.Control type="password" placeholder={locale.get("NewPassword")} value={this.data.newPassword} onChange={this.handleChange}/>
                    </Form.Group>
                    {changePasswordFailAlert}
                    <div className="d-grid gap-2">
                    <Button disabled={this.data.submitButtonDisabled} style={{ marginTop: 20 }} variant="primary" size="lg" type="submit" onSubmit={this.handleSubmit}>
                        {locale.get("ChangePassword")}
                    </Button>
                    </div>
                </Form>
            </div>
        );
    }
  }