import React from 'react';
import { Container, Form, Button, Alert } from "react-bootstrap";
import { locale } from "./Locale";
import { MessageComponent } from "./MessageComponent";

export class ResetPasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.data = {
            email: "",
            submitButtonDisabled: true,
            showResetPasswordFailAlert: false,
            showResetSuccessMessage: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        // There is no need to perform any ASCII checks here because the email input does this already
        this.data[event.target.id] = event.target.value;
        
        // Check if all the input is valid
        this.data.submitButtonDisabled = this.data.email.length > 128;
        this.setState(this.data);
    }

    handleSubmit(event) {
        let parameters = new FormData();
        parameters.append("Email", this.data.email);

        // Call the PHP script for login and account information
        fetch("https://api.anihance.com/reset-password.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success === true) {
                this.data.showResetSuccessMessage = true;
            } else {
                this.data.showResetPasswordFailAlert = true;
            }
            this.setState(this.data);
        }).catch(error => {
            this.data.showResetPasswordFailAlert = true;
            this.setState(this.data);
        });

        event.preventDefault();
    }

    render() {
        if (this.data.showResetSuccessMessage) {
            return <MessageComponent header={locale.get("ResetPasswordSuccess")}/>;
        } else {
            let resetPasswordFailAlert = null;

            if (this.data.showResetPasswordFailAlert) {
                resetPasswordFailAlert = (
                    <Alert style={{ marginTop: 20 }} variant="danger" onClick={() => { this.data.showResetPasswordFailAlert = false; this.setState(this.data); }} dismissible>
                        { locale.get("ResetPasswordFail") }
                    </Alert>
                );
            }

            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Container fluid="sm" style={{ maxWidth: 400 }}>
                    <h2 style={{ textAlign: "center" }}>{locale.get("ResetPassword")}</h2>
                    {locale.get("ResetPasswordNote")}
                    <Form style={{ paddingTop: 20 }} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>{locale.get("Email")}</Form.Label>
                        <Form.Control type="email" placeholder={locale.get("Email")} value={this.data.email} onChange={this.handleChange}/>
                    </Form.Group>
                    {resetPasswordFailAlert}
                    <div className="d-grid gap-2">
                        <Button disabled={this.data.submitButtonDisabled} style={{ marginTop: 20 }} variant="primary" size="lg" type="submit" onSubmit={this.handleSubmit}>
                            {locale.get("ResetPassword")}
                        </Button>
                    </div>
                    </Form>
                </Container>
                </div>
            );
        }
    }
  }