import React from 'react';
import { Container, Form, Button, Alert } from "react-bootstrap";
import { locale } from "./Locale";
import cookie from "react-cookies";
import { MessageComponent } from "./MessageComponent";
import { Helmet } from "react-helmet";

export class FeedbackComponent extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            email: "",
            message: "",
            submitButtonDisabled: true,
            showFeedbackSuccess: false,
            showFeedbackFailAlert: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        // Update email
        if (event.target.id === "email" && event.target.value.length < 128) {
            this.data.email = event.target.value.replace(/\s/g, '');
        }

        // Update message
        if (event.target.id === "message" && event.target.value.length < 2000) {
            this.data.message = event.target.value;
        }

        // Activate button only if some text was put in
        this.data.submitButtonDisabled = this.data.message.length === 0;
        this.setState(this.data);
    }

    handleSubmit(event) {
        let language = cookie.load("Language");

        // Set default language to english in case cookie is not found
        if (language === undefined) {
            language = 0;
        }

        let parameters = new FormData();
        parameters.append("Email", this.data.email);
        parameters.append("Message", this.data.message);
        parameters.append("Language", language);

        // Call the PHP script that handles registration
        fetch("https://api.anihance.com/feedback.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success === true) {
                this.data.showFeedbackSuccess = true;
                this.setState(this.data);
            } else {
                this.data.showFeedbackFailAlert = true;
                this.setState(this.data);
            }
        }).catch(error => {
            this.data.showFeedbackFailAlert = true;
            this.setState(this.data);
        });

        event.preventDefault();
    }

    render() {
        if (this.data.showFeedbackSuccess) {
            return <MessageComponent header={locale.get("FeedbackSuccess")} message={locale.get("FeedbackSuccessNote")}/>;
        }

        // Show login failed message
        let registerFailAlert = null;

        if (this.data.showFeedbackFailAlert) {
            registerFailAlert = (
                <Alert style={{ marginTop: 20 }} variant="danger" onClick={() => { this.data.showFeedbackFailAlert = false; this.setState(this.data); }} dismissible>
                    {locale.get("FeedbackFail")}
                </Alert>
            );
        }

        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
            <Helmet>
                <title>Anihance - {locale.get("Feedback")}</title>
                <meta name="description" content={locale.get("FeedbackNote")}/>
            </Helmet>
            <Container fluid="sm" style={{ maxWidth: 600 }}>
                <div style={{marginBottom: 20}}>
                <h2 style={{textAlign: "center"}}>{locale.get("Feedback")}</h2>
                <br/>
                {locale.get("FeedbackNote")}
                </div>
                <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label>{locale.get("EmailOptional")}</Form.Label>
                    <Form.Control type="email" placeholder={locale.get("EmailOptional")} value={this.data.email} onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group controlId="message">
                    <Form.Label>{locale.get("Message")}</Form.Label>
                    <Form.Control as="textarea" rows={9} placeholder={locale.get("Message")} value={this.data.message} onChange={this.handleChange}/>
                </Form.Group>
                {registerFailAlert}
                <div className="d-grid gap-2">
                    <Button disabled={this.data.submitButtonDisabled} style={{ marginTop: 20 }} variant="primary" type="submit" onSubmit={this.handleSubmit}>
                        {locale.get("SendFeedback")}
                    </Button>
                </div>
                </Form>
            </Container>
            </div>
        );
    }
  }