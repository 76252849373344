import React from 'react';
import { Container, Form, Button, Alert } from "react-bootstrap";
import { locale } from "./Locale";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";

export class RegisterComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.data = {
            username: "",
            usernameValid: false,
            usernameInitial: true,
            email: "",
            emailValid: false,
            emailInitial: true,
            password: "",
            passwordValid: false,
            passwordInitial: true,
            checkbox: false,
            submitButtonDisabled: true,
            showRegisterFailAlert: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        // Validate and update username
        if (event.target.id === "username" && event.target.value.length < 32) {
            this.data.username = event.target.value.replace(/\s/g, '');
            this.data.usernameValid = this.data.username.match(/^[\x21-\x7E]{1,32}$/) !== null;
            this.data.usernameInitial = false;
        }

        // Validate and update email
        if (event.target.id === "email" && event.target.value.length < 128) {
            this.data.email = event.target.value.replace(/\s/g, '');
            this.data.emailValid = this.data.email.match(/^[\x21-\x7E]{1,128}$/) !== null;
            this.data.emailInitial = false;
        }

        // Validate and update password
        if (event.target.id === "password" && event.target.value.length < 64) {
            this.data.password = event.target.value.replace(/\s/g, '');
            this.data.passwordValid = this.data.password.match(/^[\x21-\x7E]{8,64}$/) !== null;
            this.data.passwordInitial = false;
        }

        // Checkbox
        if (event.target.id === "checkbox") {
            this.data.checkbox = event.target.checked;
        }

        // Activate button only if all the input is valid
        if (!this.data.usernameInitial && !this.data.emailInitial && !this.data.passwordInitial) {
            this.data.submitButtonDisabled = !this.data.usernameValid || !this.data.emailValid || !this.data.passwordValid || !this.data.checkbox;
        }

        this.setState(this.data);
    }

    handleSubmit(event) {
        let language = cookie.load("Language");

        // Set default language to english in case cookie is not found
        if (language === undefined) {
            language = 0;
        }

        let parameters = new FormData();
        parameters.append("Username", this.data.username);
        parameters.append("Email", this.data.email);
        parameters.append("Password", this.data.password);
        parameters.append("Language", language);

        // Call the PHP script that handles registration
        fetch("https://api.anihance.com/register.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success === true) {
                window.location.replace("/register-success");
            } else {
                this.data.showRegisterFailAlert = true;
                this.setState(this.data);
            }
        }).catch(error => {
            this.data.showRegisterFailAlert = true;
            this.setState(this.data);
        });

        event.preventDefault();
    }

    render() {
        // Show login failed message
        let registerFailAlert = null;

        if (this.data.showRegisterFailAlert) {
            registerFailAlert = (
                <Alert style={{ marginTop: 20 }} variant="danger" onClick={() => { this.data.showRegisterFailAlert = false; this.setState(this.data); }} dismissible>
                    {locale.get("ErrorRegisterFail")}
                </Alert>
            );
        }

        // Add links inside the text label
        let checkboxAgreeLabel = 
            <div>
            {locale.get("CheckboxAgreeStart")}
            <a href="/license-agreement" target="_blank">{locale.get("LicenseAgreement")}</a>
            {locale.get("CheckboxAgreeMid")}
            <a href="/privacy-policy" target="_blank">{locale.get("PrivacyPolicy")}</a>
            {locale.get("CheckboxAgreeEnd")}
            </div>;

        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
            <Helmet>
                <title>Anihance - {locale.get("Register")}</title>
                <meta name="description" content={locale.get("CreateAccountHint")}/>
            </Helmet>
            <Container fluid="sm" style={{ maxWidth: 400 }}>
                <div style={{marginBottom: 20}}>
                <h2 style={{textAlign: "center"}}>{locale.get("CreateAccount")}</h2>
                {locale.get("CreateAccountHint")}
                </div>
                <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Label>{locale.get("Username")}</Form.Label>
                    <Form.Control autoFocus type="text" placeholder={locale.get("Username")} value={this.data.username} onChange={this.handleChange}/>
                    <small style={{ color: (this.data.usernameValid || this.data.usernameInitial) ? "grey" : "red" }}>{locale.get("UsernameHint")}</small>
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>{locale.get("Email")}</Form.Label>
                    <Form.Control type="email" placeholder={locale.get("Email")} value={this.data.email} onChange={this.handleChange}/>
                    <small style={{ color: (this.data.emailValid || this.data.emailInitial) ? "grey" : "red" }}>{locale.get("EmailHint")}</small>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>{locale.get("Password")}</Form.Label>
                    <Form.Control type="password" placeholder={locale.get("Password")} value={this.data.password} onChange={this.handleChange}/>
                    <small style={{ color: (this.data.passwordValid || this.data.passwordInitial) ? "grey" : "red" }}>{locale.get("PasswordHint")}</small>
                </Form.Group>
                <Form.Group controlId="checkbox">
                    <Form.Check type="checkbox" onChange={this.handleChange} label={checkboxAgreeLabel}/>
                </Form.Group>
                {registerFailAlert}
                <div className="d-grid gap-2">
                    <Button disabled={this.data.submitButtonDisabled} style={{ marginTop: 20 }} variant="primary" size="lg" type="submit" onSubmit={this.handleSubmit}>
                        {locale.get("Register")}
                    </Button>
                </div>
                </Form>
            </Container>
            </div>
        );
    }
  }