import React from 'react';
import { Container } from "react-bootstrap";
import { locale, languageEnum } from './Locale';
import cookie from "react-cookies";
import { Helmet } from "react-helmet";

export class LicenseComponent extends React.Component {
    render() {
        let language = parseInt(cookie.load("Language"));

        let pageNotTranslated = null;
        
        // Show a warning if the page has not been translated yet (or will not be)
        if (language === languageEnum.Korean || language === languageEnum.Japanese) {
            pageNotTranslated = <p style={{textAlign: "center" }}>{locale.get("PageNotTranslated")}</p>;
        }

        if (language === languageEnum.German) {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("LicenseAgreement")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 900 }}>
                    {pageNotTranslated}
                    <h2 style={{ textAlign: "center" }}>ENDBENUTZER-LIZENZVERTRAG</h2>
                    <h4 style={{ textAlign: "center" }}>Anihance Software</h4>
                    <br/>
                    <p>Dieser Endbenutzer-Lizenzvertrag ("EULA") ist ein rechtsgültiger Vertrag zwischen Ihnen (entweder eine Einzelperson oder ein Unternehmen) ("Lizenznehmer") und Moritz Schöpf ("Lizenzgeber") für Software, die Moritz Schöpf und seinen Drittanbietern und Lizenzgebern gehört, der diesem EULA beiliegt. Für die Zwecke dieser EULA bedeutet "Software" maschinenlesbare Anweisungen, die von einem Prozessor verwendet werden, um bestimmte Operationen auf dem Betriebssystem Windows 10 auszuführen. DURCH DIE VERWENDUNG DER SOFTWARE ERKENNT DER LIZENZNEHMER DIE BEDINGUNGEN DIESER EULA AN. WENN DER LIZENZNEHMER DIESE BEDINGUNGEN NICHT AKZEPTIERT, DARF DER LIZENZNEHMER DIE SOFTWARE NICHT VERWENDEN.</p>
                    <p>Dieser EULA stellt die Grundlage dar, auf der der Lizenzgeber Ihnen die Software zur Verfügung stellt. Indem Sie auf "Ich stimme zu" klicken, die Anihance Software (im Folgenden als "Software" bezeichnet) und die Dokumentation herunterladen, darauf zugreifen, installieren, ausführen oder nutzen, stimmen Sie als solche zu: (i.) dass dieser EULA eine rechtsverbindliche und gültige Vereinbarung ist, (ii.) sich an die Bestimmungen und Bedingungen dieser EULA zu halten, und (iii.) alle notwendigen Schritte zu unternehmen, um sicherzustellen, dass die Bestimmungen und Bedingungen dieser EULA nicht von einer Person oder Einrichtung unter Ihrer Kontrolle oder in Ihrem Dienst verletzt werden. Wenn Sie die Software im Namen einer Organisation verwenden, erklären Sie sich mit den Bestimmungen und Bedingungen dieser EULA für diese Organisation einverstanden und versprechen, dass Sie befugt sind, diese Organisation an diese Bestimmungen zu binden. In diesem Fall beziehen sich "Sie" und "Ihr" auch auf diese Organisation. Wenn Sie den Bedingungen dieser EULA nicht zustimmen, klicken Sie nicht auf "Ich stimme zu", laden Sie die Software nicht herunter, greifen Sie nicht auf sie zu, installieren Sie sie nicht, führen Sie sie nicht aus und benutzen Sie sie nicht. Dieser EULA enthält Gewährleistungsausschlüsse und Haftungsbeschränkungen sowie ausschließliche Rechtsmittel. Die nachstehenden Bestimmungen bilden die wesentliche Grundlage unserer Vereinbarung.</p>
                    <p>Der Lizenzgeber behält sich das Recht vor, Artikel in dieser Vereinbarung jederzeit gemäß den hier beschriebenen Verfahren zu ändern, zu modifizieren, hinzuzufügen oder zu löschen. Groß geschriebene Begriffe, die in diesem EULA ohne Definition verwendet werden, haben die in diesen Bedingungen angegebenen Bedeutungen. Unter Berücksichtigung der hierin dargelegten gegenseitigen Versprechen vereinbaren Lizenznehmer und Lizenzgeber hiermit Folgendes:</p>
                    <h4>ÄNDERUNGEN AN SOFTWARE-ANGEBOTEN</h4>
                    <p>Der Lizenzgeber kann die Preise für die Software, Abonnements, Dienstleistungen und Inhalte ändern. Wir gewähren keinen Preisschutz oder Rückerstattungen aufgrund von Preisnachlässen, Sonderangeboten oder anderen Preisänderungen aus irgendeinem Grund.</p>
                    <p>Sie erkennen an und stimmen zu, dass der Lizenzgeber das Angebot der Software aussetzen oder einstellen, die Software modifizieren, die über die Software verfügbaren Dienste ändern oder einstellen, die Art und Weise ändern kann, wie wir solche Dienste anbieten und betreiben (z. B. eine Gebühr für den Zugriff auf Funktionen oder Inhalte, die wir zuvor kostenlos zur Verfügung gestellt haben, zu erheben oder zu erhöhen), Inhalte entfernen, bestimmte Funktionen einschränken oder Ihren Zugriff auf einen Teil oder die Gesamtheit der Software, Dienste und Inhalte einschränken oder andere Änderungen an den Software- und Dienstleistungsangeboten des Lizenzgebers nach eigenem Ermessen und mit oder ohne Benachrichtigung an Sie vornehmen kann. Wir sind weder Ihnen noch Dritten gegenüber haftbar, wenn Sie eine dieser Maßnahmen ergreifen, und wir sind nicht auf die oben genannten Rechtsmittel beschränkt, wenn Sie diese Bedingungen verletzen. Sie erkennen weiterhin an und stimmen zu, dass, selbst wenn eine Kopie der Software weiterhin auf Ihrem Gerät verbleibt, nachdem wir Änderungen an unseren Software- oder Dienstleistungsangeboten vorgenommen haben, die Software möglicherweise nicht mehr so funktioniert wie vor dieser Aktion, und dass der Lizenzgeber Ihnen oder Dritten gegenüber infolgedessen keine Haftung übernimmt.</p>
                    <h4>ZWECK</h4>
                    <p>Der Zweck und die Absicht dieser Software ist es, Anime-Videos in Echtzeit zu verbessern. Sie verwendet Deep-Learning Algorithmen und funktioniert mit vielen Anwendungen von Drittanbietern.</p>
                    <h4>EIGENTUM</h4>
                    <p>Vorbehaltlich der hierin enthaltenen Bedingungen und der hierin gewährten Lizenzen hat und behält der Lizenzgeber das alleinige und ausschließliche Eigentum und alle Rechte, Titel und Anteile an den Verbesserungen des Lizenzgebers, und der Lizenznehmer hat keine Rechte, Lizenzen oder Anteile daran. </p>
                    <p>Der Lizenzgeber behält sich alle Rechte vor, die Ihnen nicht ausdrücklich gewährt werden. Sie müssen bei der Verwendung der Software alle Gesetze sowie alle anwendbaren Urheberrechts-, Marken- oder sonstigen rechtlichen Hinweise oder Einschränkungen beachten. Das werden Sie nicht tun: </p>
                    <ul>
                        <li>die Software ganz oder teilweise zu kopieren, zu reproduzieren, zu übersetzen, zu modifizieren, zu adaptieren, abgeleitete Werke zu erstellen oder zurückzuentwickeln, den Quellcode der Software abzuleiten, zu disassemblieren, zu dekompilieren oder anderweitig zu versuchen, den Quellcode der Software zu ermitteln, es sei denn, dies ist nach geltendem Recht erforderlich;</li>
                        <li>die Software auf einem Computer oder einem anderen Gerät installieren oder verwenden, das hauptsächlich als Dateiserver verwendet wird;</li>
                        <li>Entfernen, Verdecken oder Ändern von Urheberrechten, Marken, Logos oder anderen Eigentumshinweisen in oder auf der Software;</li>
                        <li>nicht autorisierte Software Dritter zu verwenden, die Informationen von oder durch die Software abfängt, "vermint" oder anderweitig sammelt, es sei denn, der Lizenzgeber hat dies nach eigenem Ermessen genehmigt;</li>
                        <li>die Software zu vertreiben, zu veröffentlichen, auszustellen, vorzuführen, zu verkaufen, zu vermieten, zu verleasen, Unterlizenzen zu vergeben, abzutreten, zu übertragen oder ein Sicherungsrecht an Ihren Rechten an der Software zu gewähren oder die Software ganz oder teilweise auf den Computer eines anderen Benutzers zu kopieren, sofern dies nicht im Rahmen dieser Vereinbarung gestattet ist;</li>
                        <li>die von der Software verwendeten Kommunikationsprotokolle in irgendeiner Weise und zu irgendeinem Zweck abzufangen, zu emulieren oder umzuleiten oder sich an Aktivitäten zu beteiligen, die die Software oder die Server des Lizenzgebers oder seiner Lieferanten oder andere Infrastruktur stören oder unterbrechen; oder</li>
                        <li>eine nicht autorisierte Verbindung mit der Software zu erleichtern, herzustellen oder aufrechtzuerhalten, einschließlich und ohne Einschränkung: (a) jede Verbindung zu einem nicht autorisierten Server, der die Server des Lizenzgebers oder seiner Lieferanten emuliert oder zu emulieren versucht; und (b) jede Verbindung mit Programmen oder Tools, die vom Lizenzgeber nach eigenem Ermessen nicht genehmigt wurden.</li>
                        <li>Die Dienste und Inhalte, auf die über die Software zugegriffen werden kann, sind ebenfalls durch geistige Eigentumsrechte und andere Gesetze in Deutschland und in anderen Ländern geschützt und unterliegen den Beschränkungen Ihrer Nutzung, die in den Lizenzbedingungen ausführlicher dargelegt sind.</li>
                    </ul>
                    <h4>LIZENZGEWÄHRUNG</h4>
                    <p>Der Lizenzgeber gewährt dem Lizenznehmer eine nicht ausschließliche, nicht übertragbare Lizenz zur Nutzung der Software und der Dokumentation ausschließlich für seinen persönlichen Betrieb an dem/den Standort(en) und in der vorgesehenen Umgebung sowie zur Vervielfältigung der Software und der Dokumentation ausschließlich zu Archivierungs- oder Sicherungszwecken, unter der Voraussetzung, dass alle Titel, Marken und Hinweise auf Urheberrechte, Eigentumsrechte und eingeschränkte Rechte in allen diesen Kopien reproduziert werden und dass alle Kopien den Bestimmungen dieses Vertrags unterliegen.</p>
                    <p>Der Lizenzgeber behält sich alle Rechte vor, die nicht ausdrücklich vom Lizenzgeber im Rahmen dieses Vertrags gewährt werden. Die Software-Codes und die Dokumentation sind durch das Urheberrecht und andere Gesetze zum Schutz geistigen Eigentums sowie durch internationale Verträge geschützt.</p>
                    <p>Die Laufzeit dieser Vereinbarung ist eine unbefristete, weltweite, nicht exklusive und nicht übertragbare Lizenz zur Nutzung der Software, ausschließlich zur Ausführung der in der Dokumentation definierten Funktionen und vorbehaltlich aller hierin enthaltenen Einschränkungen und Beschränkungen. Die Software darf nur auf den Hardware- und Softwarekomponenten, einschließlich Client-Rechnern, Servern und Internet-Arbeitsgeräten innerhalb des internen Computernetzwerks des Lizenznehmers am Standort des Lizenznehmers verwendet werden. Dieser Vertrag (einschließlich aller hierin gewährten Lizenzen) endet jedoch automatisch und sofort im Falle einer wesentlichen Verletzung seiner Bestimmungen oder Bedingungen durch eine Partei, wenn er nicht innerhalb von 30 Tagen nach Erhalt einer schriftlichen Mitteilung, in der die Verletzung beschrieben wird, durch die verletzende Partei korrigiert wird.</p>
                    <h4>LIZENZTYPEN</h4>
                    <p>Die Software wird lizenziert, nicht verkauft, und dem Lizenznehmer wird kein Eigentumsrecht übertragen, unabhängig von der Verwendung von Bedingungen in dieser EULA wie "Kauf" oder "Verkauf". Dieser EULA gewährt Ihnen die folgenden Rechte:</p>
                    <p><b>Kostenlose Testlizenz:</b> Der Lizenzgeber gewährt Ihnen eine nicht-exklusive, nicht übertragbare Testlizenz zum Testen und Evaluieren der lizenzierten Software für einen Zeitraum von vierzehn (14) Tagen. Diese Lizenz beginnt mit der Registrierung eines Anihance-Kontos auf der Website und endet vierzehn (14) Tage danach ("Testzeitraum"). Wenn Sie nicht bereit sind, die lizenzierte Software zu benutzen, löschen Sie nach dem Testzeitraum alle auf Ihrem Computer installierten Kopien mit sofortiger Wirkung. Dem Lizenznehmer ist es untersagt, die lizenzierte Software für andere Zwecke zu verwenden oder sie anderweitig zum Weiterverkauf unter den Bedingungen dieses Abschnitts anzubieten, der Lizenzgeber behält sich alle Rechte vor, die Ihnen hier nicht ausdrücklich gewährt werden.</p>
                    <p><b>Unbefristete Lizenz:</b> Als Teil der von Ihnen gewählten unbefristeten Lizenz gewährt Ihnen der Lizenzgeber eine <b>gebührenpflichtige</b>, nicht exklusive, nicht übertragbare, unbefristete, weltweite Lizenz zur Nutzung der lizenzierten Software einschließlich der Ihnen zustehenden Benutzerdokumentation, Updates und Upgrades sowie aller Ihnen zur Verfügung gestellten Plug-ins.</p>
                    <p><u>Hinweis:</u> Der Lizenznehmer kann die Software auf bis zu drei (3) Geräten (Windows 10 PCs) gleichzeitig nutzen. Wenn ein viertes Gerät hinzugefügt wird, muss der Lizenznehmer ein bereits vorhandenes Gerät von der Website entfernen, bevor er Zugang erhält. Das bedeutet, dass der Lizenznehmer die Software nur auf drei (3) gleichzeitig registrierten Geräten verwenden darf.</p>
                    <h4>LIZENZBESCHRÄNKUNGEN UND -AUSSCHLÜSSE</h4>
                    <p>Die vorstehende Lizenz erlaubt Ihnen nicht, die Software auf einem Gerät zu verwenden, das nicht in Ihrem Besitz oder unter Ihrer Kontrolle ist, und Sie dürfen die Software nicht über ein Netzwerk verteilen oder verfügbar machen, wo sie von mehreren Geräten gleichzeitig verwendet werden könnte. Es ist Ihnen nicht gestattet, die Software zu vermieten, zu verleasen, zu verleihen, zu verkaufen, weiterzuverteilen oder Unterlizenzen zu vergeben. Sie dürfen die Software nur so nutzen, wie es die Eigenschaften und Funktionalitäten der Software erlauben. Sie dürfen die Software, Aktualisierungen oder Teile davon nicht kopieren (es sei denn, dies ist durch diese Lizenz und die Nutzungsregeln ausdrücklich gestattet), dekompilieren, zurückentwickeln, disassemblieren, versuchen, den Quellcode der Software abzuleiten, modifizieren oder abgeleitete Werke der Software zu erstellen (es sei denn, eine der vorstehenden Einschränkungen ist nach geltendem Recht verboten oder die Lizenzbedingungen für die Nutzung eines in der Software enthaltenen Dienstes eines Dritten erlauben dies). Jeder Versuch, dies zu tun, stellt eine Verletzung der Rechte des Lizenzgebers und seiner Lizenzgeber dar. Wenn Sie gegen diese Einschränkung verstoßen, können Sie strafrechtlich verfolgt werden und Schadenersatz verpflichtet werden. Die Bedingungen dieser Lizenz gelten für alle Upgrades und Updates, es sei denn, es liegt ein separater Lizenzvertrag bei; in diesem Fall gelten die Bedingungen dieses separaten Lizenzvertrags entsprechend.</p>
                    <h4>GEBÜHREN</h4>
                    <p>Die hierunter gewährte Lizenz ist abhängig von der rechtzeitigen Zahlung der Gebühren durch den Lizenznehmer, wie in der vom Lizenzgeber oder vom autorisierten Wiederverkäufer des Lizenzgebers ausgestellten Rechnung angegeben. Wenn der Lizenznehmer die Lizenzgebühr für die Software noch nicht bezahlt hat, muss der Lizenznehmer die Lizenzgebühr innerhalb des in der entsprechenden Rechnung angegebenen Zeitraums oder wie anderweitig in den auf der Website veröffentlichten Preisbedingungen des Lizenzgebers vorgesehen, bezahlen. Die Nichtzahlung von Lizenzgebühren bis zum Fälligkeitsdatum führt zur sofortigen Kündigung der gemäß dieser EULA gewährten Lizenz(en).</p>
                    <p>Der Lizenznehmer ist verpflichtet, alle Transaktionssteuern zu zahlen, unabhängig davon, wie oder von wem sie erhoben werden, wobei es sich um Steuern handelt, die sich aus dem EULA ergeben, mit Ausnahme der Steuern, die auf das Nettoeinkommen des Lizenzgebers erhoben werden. Zu diesen Transaktionssteuern können unter anderem lokale, staatliche, provinzielle, bundesstaatliche oder ausländische Steuern, Abgaben, Zölle oder ähnliche staatliche Veranlagungen jeglicher Art gehören, einschließlich Mehrwertsteuern, Verbrauchssteuern, Steuern auf Waren und Dienstleistungen, GST/HST, Verbrauchssteuern, Verkaufs-, Nutzungs- oder ähnliche Steuern und alle Quellensteuern (zusammen "Steuern").</p>
                    <p>Alle gemäß diesem EULA gezahlten Beträge sind in voller Höhe und ohne Steuerermäßigung zahlbar. Wenn der Lizenzgeber gesetzlich verpflichtet ist, Steuern zu zahlen oder einzuziehen, für die der Lizenznehmer gemäß diesem Abschnitt verantwortlich ist, wird der entsprechende Betrag dem Lizenznehmer in Rechnung gestellt und von diesem gezahlt, es sei denn, der Lizenznehmer stellt dem Lizenzgeber eine gültige, von der zuständigen Steuerbehörde genehmigte Steuerbefreiungsbescheinigung zur Verfügung. Wenn der Lizenznehmer von einer staatlichen Steuerbehörde aufgefordert wird, einen Betrag von einer hiernach fälligen Zahlung einzubehalten und diesen Betrag an die Regierungsbehörde zu zahlen, werden die Preise hochgerechnet, so dass die Nettozahlung dem ursprünglichen Preis entspricht.</p>
                    <h4>RECHTE AN GEISTIGEM EIGENTUM</h4>
                    <p>Der Lizenznehmer erkennt an und stimmt zu, dass die Software und alle damit verbundenen Urheberrechte, Patente, Marken, Geschäftsgeheimnisse und andere geistige Eigentumsrechte das Eigentum des Lizenzgebers sind und bleiben. Darüber hinaus erkennen Sie an und stimmen zu, dass der Quell- und Objektcode der Software sowie das Format, die Verzeichnisse, die Abfragen, die Algorithmen, die Struktur und die Organisation der Software das geistige Eigentum und urheberrechtlich geschützte und vertrauliche Informationen des Unternehmens und seiner verbundenen Unternehmen, Lizenzgeber und Lieferanten sind und bleiben. Sofern nicht ausdrücklich in dieser Lizenz angegeben, werden dem Lizenznehmer keine geistigen Eigentumsrechte in oder an der Software durch Implikation, Rechtsverwirkung oder andere Rechtstheorien gewährt, und alle Rechte in und an der Software, die nicht ausdrücklich in dieser Lizenz gewährt werden, sind hiermit dem Lizenznehmer vorbehalten und bleiben ihm vorbehalten.</p>
                    <p>Sie sind nicht berechtigt, die Marken des Lizenznehmers ohne vorherige schriftliche Zustimmung des Lizenznehmers, die aus irgendeinem oder keinem Grund verweigert werden kann, in Werbung, Reklame oder auf irgendeine andere kommerzielle Weise zu verwenden.</p>
                    <h4>VERTRAULICHKEIT</h4>
                    <p>Damit sind die Software, die Dokumentation, die Spezifikationen und die Bedingungen dieser Vereinbarung gemeint. Der Lizenznehmer erkennt den vertraulichen und urheberrechtlich geschützten Charakter der vertraulichen Informationen an und erklärt sich damit einverstanden, dass er keine vertraulichen Informationen zu irgendeinem Zweck an andere Personen, Firmen, Unternehmen oder andere Körperschaften weitergeben oder offenlegen darf, außer an die Mitarbeiter des Lizenznehmers, die diese vertraulichen Informationen kennen müssen, um ihre arbeitsrechtlichen Pflichten in Übereinstimmung mit den Rechten des Lizenznehmers aus diesem Vertrag erfüllen zu können.</p>
                    <p>Der Lizenznehmer hat die vertraulichen Informationen vor Diebstahl, Piraterie oder unbefugtem Zugriff in einer Weise zu sichern und zu schützen, die mindestens den Schutzmaßnahmen entspricht, die der Lizenznehmer zum Schutz seiner eigenen vertraulichsten Informationen anwendet. Der Lizenznehmer informiert seine Mitarbeiter über ihre Verpflichtungen aus diesem Vertrag und unternimmt die unter den gegebenen Umständen angemessenen oder vom Lizenzgeber verlangten Schritte, um eine unbefugte Offenlegung, Vervielfältigung oder Nutzung der vertraulichen Informationen zu verhindern.</p>
                    <p>Der Lizenznehmer nimmt zur Kenntnis und stimmt zu, dass der Lizenzgeber im Falle eines Verstoßes des Lizenznehmers gegen diesen Vertrag irreparable Schäden erleidet, die nicht durch Geldschäden kompensiert werden, und dass ihm daher kein angemessener Rechtsbehelf zur Verfügung steht. Dementsprechend hat der Lizenzgeber Anspruch auf eine vorläufige und endgültige einstweilige Verfügung, ohne dass in diesem Zusammenhang eine Kaution oder eine Verpflichtung zur Verhinderung eines weiteren Verstoßes gegen diese Vertraulichkeitsverpflichtungen oder einer weiteren unbefugten Nutzung vertraulicher Informationen gestellt werden muss. Dieser Rechtsbehelf ist separat und unabhängig von allen anderen Rechtsbehelfen, die dem Lizenzgeber zustehen.</p>
                    <p>Der Lizenznehmer wird den Lizenzgeber unverzüglich nach Entdeckung einer verbotenen Nutzung oder Offenlegung der vertraulichen Informationen oder einer anderen Verletzung dieser Geheimhaltungspflichten durch den Lizenznehmer benachrichtigen und mit dem Lizenzgeber uneingeschränkt zusammenarbeiten, um dem Lizenzgeber zu helfen, wieder in den Besitz der vertraulichen Informationen zu gelangen und die weitere verbotene Nutzung oder Offenlegung der vertraulichen Informationen zu verhindern.</p>
                    <h4>DRITTANBIETER</h4>
                    <p>Die Software kann Software von Drittanbietern verwenden oder einschließen, die Open Source und Lizenzbedingungen von Drittanbietern unterliegt ("Software von Drittanbietern"). Der Lizenznehmer erkennt an und stimmt zu, dass der Lizenznehmer das Recht hat, diese Drittanbieter-Software als Teil der Software zu nutzen, die den Bedingungen der Open Source- oder Drittanbieter-Lizenz unterliegt, die auf diese Drittanbieter-Software anwendbar ist, einschließlich, aber nicht beschränkt auf alle anwendbaren Anerkennungen, Lizenzbedingungen und Haftungsausschlüsse, die darin enthalten sind. Im Falle eines Widerspruchs zwischen den Bedingungen dieser Lizenz und den Bedingungen solcher Open-Source- oder Drittanbieterlizenzen gelten die Bedingungen der Open-Source- oder Drittanbieterlizenzen in Bezug auf die Nutzung der betreffenden Drittanbietersoftware durch den Lizenznehmer. In keinem Fall darf die Software oder Komponenten davon als "Open Source"- oder "öffentlich verfügbare" Software angesehen werden.</p>
                    <p>Die lizenzierte Software kann Software enthalten, die von Drittanbietern stammt, und ohne die allgemeine Anwendbarkeit der anderen Bestimmungen dieses Vertrags einzuschränken, erklären Sie sich damit einverstanden, dass (a.) das Eigentumsrecht an der in der lizenzierten Software enthaltenen Drittsoftware bei der Drittpartei verbleibt, die diese geliefert hat; (b.) der Lizenznehmer die mit der Lizenzierten Software erhältliche Drittsoftware in keiner Weise vertreibt. Der Lizenzgeber lehnt jegliche Haftung für die Folgen solcher Handlungen durch solche Dritte ab.</p>
                    <p>Der Lizenznehmer und der Lizenzgeber erkennen an und stimmen zu, dass im Falle einer Behauptung einer dritten Partei, dass die Software oder Ihr Besitz oder Ihre Nutzung der Software die geistigen Eigentumsrechte einer dritten Partei verletzt, der Lizenznehmer (und nicht der Lizenzgeber) für die Untersuchung, Verteidigung, Beilegung und Erledigung einer solchen Behauptung der Verletzung geistigen Eigentums verantwortlich ist. Sie werden den Lizenzgeber jedoch unverzüglich schriftlich über einen solchen Anspruch informieren.</p>
                    <h4>SUPPORT</h4>
                    <p>Der Lizenzgeber bietet keinen Support für die Software an. Sie können technische Supportleistungen für eine unbefristete Lizenz erwerben, indem Sie die dann aktuelle Wartungs- und Supportgebühr bezahlen. Der Lizenzgeber fördert das Feedback seiner Kunden. Wenn Sie Feedback zu Ihrem Kauf oder der Verwendung der Software haben, senden Sie dieses Feedback bitte an den Support des Lizenzgebers unter support@anihance.com.</p>
                    <h4>DATENSICHERHEIT UND DATENSCHUTZ</h4>
                    <p>Die Allgemeine Datenschutzverordnung 2016/679 (GDPR) ist ein Gesetz der Europäischen Union über den Datenschutz und den Schutz der Privatsphäre für Personen, die in der Europäischen Union und im Europäischen Wirtschaftsraum ansässig sind. Es kann sein, dass Sie die GDPR einhalten müssen; für weitere Informationen sollten Sie, falls erforderlich, Ihren eigenen professionellen Rat einholen.</p>
                    <p>Wenn die Verarbeitung personenbezogener Daten Ihrer autorisierten Benutzer, Kunden oder Klienten (personenbezogene Daten) durch uns durch die GDPR geregelt ist, gelten die zusätzlichen Bestimmungen der Datenschutzerklärung des Lizenzgebers und sind Bestandteil dieses Abkommens. Sie garantieren, dass Sie durch den Zugriff, die Registrierung, die Installation oder die Verwendung der Software die Datenschutzerklärung gelesen haben und diese akzeptieren. Die Datenschutzerklärung ist auf der Website und auf Anfrage erhältlich.</p>
                    <p>Sie erkennen an und stimmen zu, dass der Lizenzgeber bei der Erhebung, Speicherung und Verarbeitung personenbezogener Daten durch die Dienste als Datenverarbeiter (wie in der GDPR definiert) für die Zwecke der GDPR handelt. Sie müssen alle erforderlichen Einwilligungen von der betreffenden Person einholen, damit der Lizenzgeber personenbezogene Daten in Übereinstimmung mit dieser Vereinbarung und, falls zutreffend, mit dem Datenschutzgesetz sammeln, verwenden, aufbewahren und verarbeiten kann.</p>
                    <p>Sie bestätigen und erklären sich damit einverstanden, dass die autorisierten Benutzer keine personenbezogenen Daten an die Software übermitteln, es sei denn, dies wird ausdrücklich über die Benutzeroberfläche angefordert (z. B. kann ein Feld mit E-Mail beschriftet sein, wenn die E-Mail eines Benutzers angefordert wird). Wenn persönliche Informationen angefordert werden, wird der Lizenzgeber angemessene Maßnahmen ergreifen, um die übermittelten Informationen zu schützen.</p>
                    <p>Der Lizenzgeber und seine Tochtergesellschaften dürfen in regelmäßigen Abständen technische und verwandte Daten bezüglich der von Ihnen lizenzierten Software sammeln und verwenden, einschließlich anonymisierter Daten über die Verwendung der Software durch den autorisierten Benutzer. Der Lizenzgeber wird diese Daten verwenden, um die Wartung und Unterstützung in Bezug auf die Software zu erleichtern, seine Software zu verbessern und dem Lizenznehmer weitere Dienstleistungen oder Technologien zur Verfügung zu stellen.</p>
                    <p>Der Lizenzgeber muss sicherstellen, dass die Nutzung aller Daten durch den Lizenzgeber jederzeit in Übereinstimmung mit allen anwendbaren lokalen, staatlichen, bundesstaatlichen und internationalen Gesetzen und Vorschriften ("Gesetze") erfolgt. Der Lizenznehmer sichert dies zu und gewährleistet dass:</p>
                    <ul>
                        <li>der Lizenznehmer alle erforderlichen Rechte, Freigaben und Genehmigungen erhalten hat, um dem Lizenzgeber alle Daten des Lizenznehmers zur Verfügung zu stellen und die dem Lizenzgeber in diesem Vertrag gewährten Rechte zu gewähren, und</li>
                        <li>Die Daten des Lizenznehmers und ihre Übertragung an den Lizenzgeber und ihre Nutzung durch den Lizenzgeber, wie vom Lizenznehmer im Rahmen dieses Abkommens genehmigt, verstoßen nicht gegen Gesetze (einschließlich, aber nicht beschränkt auf die Gesetze zur Exportkontrolle und zur elektronischen Kommunikation) oder Rechte Dritter, einschließlich, aber nicht beschränkt auf geistige Eigentumsrechte, Datenschutzrechte oder Veröffentlichungsrechte, und jede Nutzung, Sammlung und Offenlegung, die durch dieses Abkommen genehmigt wird, steht nicht im Widerspruch zu den Bedingungen der geltenden Datenschutzrichtlinien. Der Lizenzgeber übernimmt keine Verantwortung oder Haftung für die Daten des Lizenznehmers, und der Lizenznehmer ist allein verantwortlich für die Daten des Lizenznehmers und die Folgen ihrer Verwendung, Offenlegung, Speicherung oder Übertragung.</li>
                    </ul>
                    <p>Der Lizenznehmer erkennt auch an, dass der Lizenzgeber nicht als Ihr Geschäftspartner oder Unterauftragnehmer agiert, es sei denn, dies wird vom Lizenzgeber ausdrücklich angegeben. Ungeachtet anders lautender Bestimmungen übernimmt der Lizenzgeber im Rahmen dieses Vertrags keine Haftung für sensible Daten.</p>
                    <p>Sie verpflichten sich, den Lizenzgeber zu verteidigen, zu entschädigen und schadlos zu halten von und gegen alle Verluste, Kosten, Haftungen oder Schäden, einschließlich Anwaltsgebühren, für die der Lizenzgeber haftbar wird, die sich aus oder im Zusammenhang mit Ansprüchen in Bezug auf die Daten des Lizenznehmers ergeben, einschließlich, aber nicht beschränkt auf Ansprüche Dritter, die behaupten, dass die Daten des Lizenznehmers oder die Nutzung der vom Lizenzgeber bereitgestellten Dienste durch den Lizenznehmer gegen diesen Vertrag verstoßen, die geistigen Eigentumsrechte Dritter verletzen oder missbräuchlich nutzen oder gegen geltendes Recht verstoßen. Diese Entschädigungsverpflichtung unterliegt der Bedingung, dass Sie Folgendes erhalten:</p>
                    <ul>
                        <li>sofortige schriftliche Benachrichtigung über einen solchen Anspruch mit genügend Zeit, damit Sie vorurteilsfrei reagieren können;</li>
                        <li>das ausschließliche Recht, die Untersuchung, Verteidigung oder Beilegung eines solchen Anspruchs zu kontrollieren und zu leiten; und</li>
                        <li>jede zumutbare notwendige Kooperation des Lizenzgebers auf Ihre Kosten.</li>
                    </ul>
                    <h4>KÜNDIGUNG DES KONTOS</h4>
                    <p>Sie erklären sich damit einverstanden, dass wir Ihr Konto aus beliebigen Gründen nach eigenem Ermessen und ohne Vorankündigung kündigen können. Gründe für eine solche Kündigung können sein: (i.) längere Inaktivitätsperioden, (ii.) Verletzung dieser Vereinbarung, (iii.) betrügerisches, belästigendes oder missbräuchliches Verhalten oder (iv.) Verhalten, das für andere Benutzer, Dritte und das Gemeinschaftsethos unserer Software oder unsere Geschäftsinteressen schädlich ist. Für den Fall, dass wir Ihr Konto kündigen, dürfen Sie sich ohne unsere ausdrückliche Genehmigung nicht erneut für die Software registrieren. Wenn Sie der Meinung sind, dass irrtümlicherweise Maßnahmen gegen Ihr Konto ergriffen wurden, kontaktieren Sie uns bitte unter support@anihance.com.</p>
                    <p>Darüber hinaus können wir, wenn wir nach unserem alleinigen Ermessen der Ansicht sind, dass ein Verstoß gegen diese Vereinbarung oder ein illegales oder unangemessenes Verhalten vorliegt, jede andere Korrekturmaßnahme ergreifen, die wir für angemessen halten. Wir behalten uns das Recht vor, vermutete Verstöße gegen diese Vereinbarung oder illegales und unangemessenes Verhalten durch die Software zu untersuchen. Der Lizenzgeber wird in vollem Umfang mit allen Ermittlungen der Strafverfolgungsbehörden oder gerichtlichen Anordnungen kooperieren, die uns auffordern oder anweisen, die Identität, das Verhalten oder die Aktivitäten von Personen offenzulegen, von denen angenommen wird, dass Sie gegen diese Vereinbarung verstoßen oder illegales Verhalten an den Tag gelegt haben. Der Lizenznehmer kann jederzeit und aus beliebigen Gründen die Kündigung Ihres Kontos verlangen, indem er eine E-Mail an support@anihance.com sendet.</p>
                    <p>Jegliche Aussetzung oder Kündigung Ihres Kontos hat keine Auswirkungen auf Ihre Verpflichtungen uns gegenüber gemäß diesem Vertrag (einschließlich, aber nicht beschränkt auf Eigentum, Entschädigung, jegliche Zusicherungen und Gewährleistungen Ihrerseits und Haftungsbeschränkung), die nach ihrem Sinn und Zusammenhang eine solche Aussetzung oder Kündigung überdauern sollen.</p>
                    <h4>KOMPATIBILITÄT</h4>
                    <p>Der Lizenzgeber garantiert nicht, dass die Software mit dem Gerät des Lizenznehmers oder einem anderen Stück Hardware, Software, Ausrüstung oder Gerät, das auf dem Gerät des Lizenznehmers installiert ist oder in Verbindung mit diesem Gerät verwendet wird, kompatibel oder interoperabel ist. Darüber hinaus erkennen Sie an, dass Kompatibilitäts- und Interoperabilitätsprobleme dazu führen können, dass die Leistung Ihres Geräts abnimmt oder vollständig ausfällt und zu dauerhaften Schäden am Gerät des Lizenznehmers, zum Verlust der auf Ihrem Gerät befindlichen Daten und zur Verfälschung der Software und der auf dem Gerät des Lizenznehmers befindlichen Dateien führen kann; der Lizenznehmer erkennt an und stimmt zu, dass der Lizenzgeber und unsere verbundenen Unternehmen, Partner, Lieferanten und Lizenzgeber gegenüber dem Lizenznehmer keine Haftung für Verluste übernehmen, die aus oder in Verbindung mit Kompatibilitäts- oder Interoperabilitätsproblemen entstehen.</p>
                    <h4>AKTUALISIERUNGEN</h4>
                    <p>Der Lizenzgeber darf dem Lizenznehmer Aktualisierungen, Upgrades, Ergänzungen und Zusatzkomponenten (falls vorhanden) der Software, einschließlich Fehlerbehebungen, Service-Upgrades (Teile oder das Ganze) und Aktualisierungen, Erweiterungen und Funktionsverbesserungen oder Löschung der Software (einschließlich völlig neuer Versionen), (zusammenfassend "Update" genannt) nach dem Datum, an dem Sie Ihre ursprüngliche Kopie der Software erhalten haben, zur Verfügung stellen. Dieser EULA gilt für alle und jede Komponente der Aktualisierung, es sei denn, wir stellen zusammen mit einer solchen Aktualisierung andere Bedingungen zur Verfügung.</p>
                    <h4>HAFTUNGSAUSSCHLUSS, HAFTUNGSBESCHRÄNKUNG</h4>
                    <p>SIE ERKENNEN AUSDRÜCKLICH AN, DASS DIE NUTZUNG DER SOFTWARE AUF IHR EIGENES RISIKO ERFOLGT. SOWEIT NACH GELTENDEM RECHT ZULÄSSIG, WIRD DIE SOFTWARE AUF EINER "AS IS"- UND "AS AVAILABLE"-BASIS GELIEFERT. LIZENZGEBER, PARTNER UND ANGESCHLOSSENE DIENSTANBIETER GEBEN KEINE GARANTIEN, BEDINGUNGEN, GEWÄHRLEISTUNGEN JEGLICHER ART, AUSDRÜCKLICHE, STILLSCHWEIGENDE ODER GESETZLICHE ODER ANDERE BEDINGUNGEN, EINSCHLIESSLICH DERER, DIE SIE BETREFFEN, UND LEHNEN DIESE HIERMIT AB:</p>
                    <ul>
                        <li>KONFORMITÄT, GENAUIGKEIT, AKTUALITÄT, VOLLSTÄNDIGKEIT, ZUVERLÄSSIGKEIT ODER SICHERHEIT;</li>
                        <li>EIGNUNG FÜR EINE BESTIMMTE VERWENDUNG;</li>
                        <li>STILLSCHWEIGENDE GARANTIEN DES EIGENTUMSRECHTS, NICHTVERLETZUNG VON RECHTEN;</li>
                        <li>MARKTWERT; ODER</li>
                        <li>IHRE ZUFRIEDENHEIT</li>
                    </ul>
                    <p>DER LIZENZGEBER GARANTIERT NICHT, DASS DIE SOFTWARE UNUNTERBROCHEN ODER FEHLERFREI LÄUFT, DASS FEHLER BEHOBEN WERDEN ODER DASS DIE SOFTWARE FREI VON VIREN ODER ANDEREN SCHÄDLICHEN KOMPONENTEN IST. SIE ÜBERNEHMEN DIE GESAMTE VERANTWORTUNG FÜR DIE AUSWAHL DER SOFTWARE, UM DIE VON IHNEN BEABSICHTIGTEN ERGEBNISSE ZU ERZIELEN, SOWIE FÜR DIE INSTALLATION, DIE NUTZUNG UND DIE MIT DER SOFTWARE ERZIELTEN ERGEBNISSE.</p>
                    <p>SOWEIT NACH GELTENDEM RECHT ZULÄSSIG, HAFTEN LIZENZGEBER, VERTRIEBSPARTNER UND VERBUNDENE DIENSTANBIETER IN KEINEM FALL FÜR VERLUSTE ODER SCHÄDEN, DIE IM ZUSAMMENHANG MIT DER NUTZUNG DER SOFTWARE ODER DAMIT VERBUNDENER DIENSTLEISTUNGEN DRITTER ENTSTEHEN. DIES UMFASST OHNE EINSCHRÄNKUNG (A.) ALLE VERLUSTE JEGLICHER ART, OB AUS UNERLAUBTER HANDLUNG (EINSCHLIESSLICH FAHRLÄSSIGKEIT ODER VERLETZUNG DER GESETZLICHEN PFLICHT), VERTRAG, FALSCHER DARSTELLUNG (OB UNSCHULDIG ODER FAHRLÄSSIG) ODER ANDERWEITIG, (B.) DIREKTE VERLUSTE, (C.) UNBEABSICHTIGTE VERLUSTE, (D.) ZUFÄLLIGE VERLUSTE, (E.) FOLGESCHÄDEN UND (F.) INDIREKTE VERLUSTE.</p>
                    <p>UNGEACHTET DER OBEN GENANNTEN HAFTUNGSBESCHRÄNKUNGEN BESTEHT IHR EINZIGES RECHTSMITTEL IM FALLE EINES RECHTSSTREITS MIT DEM LIZENZGEBER ODER SEINEN LIZENZGEBERN, VERTRIEBSPARTNERN UND VERBUNDENEN DIENSTANBIETERN DARIN, DIE NUTZUNG DER SOFTWARE EINZUSTELLEN UND GEGEBENENFALLS SCHADENERSATZ FÜR IHRE VERLUSTE ZU VERLANGEN. IN KEINEM FALL SIND DER LIZENZGEBER, SEINE VERBUNDENEN UNTERNEHMEN, LIZENZGEBER, PARTNER UND ANGESCHLOSSENEN DIENSTANBIETER FÜR SCHÄDEN HAFTBAR, DIE ÜBER DEN BETRAG HINAUSGEHEN, DEN SIE AN DEN LIZENZGEBER FÜR DIE SOFTWARE IN DEN ZWÖLF (12) MONATEN UNMITTELBAR VOR DEM ZEITPUNKT, AN DEM IHR KLAGEGRUND AUFTRAT, GEZAHLT HABEN.</p>
                    <p>NICHTS IN DIESEM ABSCHNITT BERÜHRT DIE HAFTUNG DES LIZENZGEBERS FÜR TOD ODER KÖRPERVERLETZUNG AUFGRUND VON FAHRLÄSSIGKEIT DES LIZENZGEBERS, FÜR BETRUG ODER BETRÜGERISCHE FALSCHDARSTELLUNG SOWIE JEDE ANDERE HAFTUNG, DIE NACH GELTENDEM RECHT NICHT AUSGESCHLOSSEN ODER BESCHRÄNKT WERDEN KANN.</p>
                    <p>FÜR DIE ZWECKE DIESES ABSCHNITTS SIND DIE LIZENZGEBER, VERTRIEBSPARTNER UND VERBUNDENEN DIENSTANBIETER DES UNTERNEHMENS DRITTBEGÜNSTIGTE DER HIER FESTGELEGTEN HAFTUNGSBESCHRÄNKUNGEN, UND SIE KÖNNEN DIESER EULA GEGEN SIE DURCHSETZEN.</p>
                    <p>Ungeachtet anders lautender Bestimmungen in diesem Abschnitt sind alle Gewährleistungen im Rahmen dieses Lizenzvertrags ungültig, wenn der Benutzer Änderungen an der Software vorgenommen hat oder erlaubt hat, dass Änderungen anders als durch oder mit ausdrücklicher, schriftlicher Genehmigung des Lizenzgebers vorgenommen werden.</p>
                    <h4>INDEMNITÄT</h4>
                    <p>Sie entschädigen, verteidigen und halten den Lizenzgeber und alle seine leitenden Angestellten, Direktoren, Eigentümer, Mitarbeiter, Agenten, Informationslieferanten, Dienstleister, Verkäufer, Auftragnehmer, Tochtergesellschaften, Partner und Lizenzgeber (zusammen die "Anihance-Parteien" ) von und gegen jegliche Haftung, Verluste, Kosten und Ausgaben (einschließlich Anwaltsgebühren), die einer der Unternehmensparteien in Verbindung mit einem Anspruch entstehen, einschließlich, aber nicht beschränkt auf Ansprüche wegen Verleumdung, Verletzung von Rechten der Öffentlichkeit oder der Privatsphäre, Urheberrechtsverletzung oder Markenrechtsverletzung, die sich daraus ergeben: Ihre Nutzung der Software; jegliche Nutzung oder angebliche Nutzung Ihres Kontos oder Ihrer Passwörter durch eine Person, unabhängig davon, ob diese von Ihnen autorisiert ist oder nicht; Ihre Verbindung zu den Servern der Software oder ihrer Anbieter; Verletzung dieses Vertrags durch den Lizenznehmer; oder Ihre Verletzung der Rechte einer anderen natürlichen oder juristischen Person. Der Lizenzgeber behält sich das Recht vor, auf eigene Kosten die ausschließliche Verteidigung und Kontrolle über alle Angelegenheiten zu übernehmen, für die Sie Moritz Schöpf entschädigen müssen, und Sie werden bei der Verteidigung des Unternehmens gegen diese Ansprüche kooperieren.</p>
                    <h4>GELTENDES RECHT / STREITBEILEGUNG</h4>
                    <p>Die Parteien vereinbaren, dass dieser EULA und die Auslegung ihrer Bedingungen den Gesetzen Deutschlands unterliegen und in Übereinstimmung mit diesen ausgelegt werden und der ausschließlichen Gerichtsbarkeit der in Deutschland ansässigen Gerichte unterliegen. Wenn Sie in einem Land wohnen, in dem die Gesetze der angegebenen Gerichtsbarkeit von der Anwendung ausgeschlossen sind, gelten die Gesetze Ihres Landes für solche Streitigkeiten im Zusammenhang mit diesen Bedingungen. Andernfalls erklären Sie sich damit einverstanden, dass die Gesetze Deutschlands für diesen Lizenzvertrag sowie alle Ansprüche, Streitigkeiten, Klagen oder Probleme, die sich daraus oder in Verbindung damit ergeben könnten, unter Ausschluss der Grundsätze des Kollisionsrechts gelten. Jede Klage oder jedes Verfahren im Zusammenhang mit diesem Lizenzvertrag muss vor dem Landgericht München, Deutschland, verhandelt werden, und jede Partei unterwirft sich unwiderruflich der Gerichtsbarkeit und dem Gerichtsstand eines solchen Gerichts in einer solchen Klage oder Streitigkeit, mit der Ausnahme, dass der Lizenzgeber Unterlassungsansprüche vor jedem Gericht geltend machen kann, das für den Schutz seiner vertraulichen Informationen oder seines geistigen Eigentums zuständig ist.</p>
                    <p>Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf (1980) wird hiermit in seiner Gesamtheit von Software zu diesem Lizenzvertrag ausgeschlossen. Sofern nicht gesetzlich vorgeschrieben, dürfen weder eine Partei noch ihre Vertreter ohne die vorherige schriftliche Zustimmung beider Parteien die Existenz, den Inhalt oder die Ergebnisse eines Schiedsgerichtsverfahrens im Rahmen dieses Vertrags offenlegen. Die Bestimmungen des Übereinkommens der Vereinten Nationen über Verträge über den Verkauf von Waren gelten nicht für diesen EULA.</p>
                    <h4>ÜBERPRÜFUNG</h4>
                    <p>Der Lizenzgeber hat das Recht, Ihre Nutzung der lizenzierten Software zu überprüfen, indem er Sie mindestens sieben (7) Tage im Voraus schriftlich über seine Absicht informiert, eine solche Überprüfung in Ihren Einrichtungen während der normalen Geschäftszeiten durchzuführen.</p>
                    <h4>VERSCHIEDENES</h4>
                    <p><b>Gültigkeit:</b> Sollte ein Teil dieser Vereinbarung für rechtswidrig oder nicht durchsetzbar befunden werden, so bleibt die Gültigkeit oder Durchsetzbarkeit der übrigen Bestimmungen dieser Vereinbarung davon unberührt.</p>
                    <p><b>Verbindliche Vereinbarung:</b> Dieses Abkommen ist für die Vertragsparteien, ihre jeweiligen Nachfolger und Rechtsnachfolger verbindlich und kommt Ihnen zugute. Der Lizenznehmer darf seine Rechte und Pflichten aus diesem Vertrag nicht ohne die vorherige schriftliche Zustimmung des Lizenzgebers abtreten.</p>
                    <p><b>Kein Verzicht:</b> Die Nichtausübung von Rechten oder Rechtsmitteln im Rahmen dieser Vereinbarung durch eine der Parteien bedeutet nicht die Annahme des Ereignisses, das zu diesem Recht oder Rechtsmittel führt. Kein Versäumnis oder Verzug bei der Durchsetzung eines Rechts oder der Ausübung eines Rechtsmittels gilt als Verzicht auf ein Recht oder Rechtsmittel.</p>
                    <p><b>Salvatorische Klausel und Reformation:</b> Jede Bestimmung dieser Lizenzvereinbarung ist eine separat einklagbare Bestimmung. Sollte eine Bestimmung dieses Lizenzvertrages als nicht durchsetzbar oder rechtswidrig bestimmt werden oder werden, so ist diese Bestimmung so weit wie nötig zu reformieren, damit dieser Lizenzvertrag in Übereinstimmung mit den durch diese Reformierung geänderten Bedingungen in Kraft bleibt.</p>
                    <p><b>Anwaltskosten:</b> Der Lizenznehmer ist für die angemessenen Anwaltsgebühren des Lizenzgebers im Zusammenhang mit der Durchsetzung der Bestimmungen dieses Vertrags oder der Eintreibung der gemäß diesem Vertrag fälligen Beträge verantwortlich.</p>
                    <p><b>Überleben:</b> Die Abschnitte 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0 und 13.0 überdauern die Beendigung dieser Vereinbarung aus beliebigem Grund.</p>
                    <h4>KONTROLLWECHSEL</h4>
                    <p>Der Lizenznehmer darf diesen EULA im Falle einer Fusion, Übernahme oder eines Kontrollwechsels an die nachfolgenden Parteien abtreten; vorausgesetzt jedoch, dass in jedem Fall (a.) der Lizenzgeber innerhalb von neunzig (90) Tagen nach einer solchen Abtretung schriftlich benachrichtigt wird, (b.) der Abtretungsempfänger sich mit den in dieser EULA enthaltenen Bestimmungen und Bedingungen einverstanden erklärt und (c.) der vorherige Abtretungsempfänger nach einer solchen Abtretung die Software(n), die unter dieser EULA lizenziert wurde(n), nicht weiter verwendet. Der Lizenzgeber kann seine Rechte und Pflichten gemäß dieser EULA ohne Zustimmung des Lizenznehmers abtreten. Jeder zugelassene Abtretungsempfänger ist an die Bestimmungen und Bedingungen dieser EULA gebunden.</p>
                    <h4>ANMERKUNGEN</h4>
                    <p>Jede Mitteilung, die einer Partei im Rahmen oder in Verbindung mit diesem Vertrag gemacht wird, bedarf der Schriftform und muss:</p>
                    <ul>
                        <li>persönlich oder durch vorausbezahlte Post oder einen anderen Zustelldienst am nächsten Werktag an ihrem eingetragenen Sitz (wenn es sich um ein Unternehmen handelt) oder ihrem Hauptgeschäftssitz (in jedem anderen Fall) zugestellt werden; oder</li>
                        <li>per Fax oder E-Mail an seine Hauptfaxnummer oder E-Mail-Adresse gesendet werden.</li>
                    </ul>
                    <p>Jede Mitteilung gilt als eingegangen:</p>
                    <ul>
                        <li>bei persönlicher Übergabe gegen Unterzeichnung eines Übergabeprotokolls;</li>
                        <li>wenn Sie per vorausbezahlter Post oder mit einem anderen Zustelldienst am nächsten Werktag verschickt werden,</li>
                        <li>per Fax oder E-Mail an seine Hauptfaxnummer oder E-Mail-Adresse gesendet wird.</li>
                    </ul>
                    <p>Diese Klausel gilt nicht für die Zustellung von Verfahren oder anderen Dokumenten im Rahmen eines Gerichtsverfahrens oder gegebenenfalls eines Schiedsverfahrens oder einer anderen Methode der Streitbeilegung. Für die Zwecke dieser Klausel umfasst "schriftlich" nicht E-Mail.</p>
                    <h4>GESAMTE VEREINBARUNG</h4>
                    <p>Diese Vereinbarung und andere Bedingungen und Richtlinien umfassen die gesamte Vereinbarung zwischen den Parteien in Bezug auf den Vertragsgegenstand und ersetzen und verschmelzen alle früheren Vorschläge, Absprachen und alle anderen mündlichen und schriftlichen Vereinbarungen zwischen den Parteien in Bezug auf die Vereinbarung.</p>
                    <h4>KONTAKT</h4>
                    <p>Sie können den Entwickler kontaktieren unter</p>
                    <p>Moritz Schöpf<br/>Krüner Str. 126, 81377 München, Deutschland.<br/>E-Mail: moritz.schoepf@anihance.com</p>
                    <p><u>Hinweis:</u> Einige der Überschriften in diesen Vertragsbedingungen erklären möglicherweise nicht vollständig deren Inhalt und Zweck, daher empfehlen wir Ihnen, diese Bedingungen vor der Verwendung der Anihance Software sorgfältig durchzulesen.</p>
                    <p>Wenn Sie Fragen, Ansprüche oder Beschwerden haben, sollten Sie uns über die Kontakt- oder Hilfefunktion des entsprechenden Dienstes benachrichtigen.</p>
                    <p><b>Überarbeitet und aktualisiert: Dezember, 2020</b></p>
                </Container>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("LicenseAgreement")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 900 }}>
                    {pageNotTranslated}
                    <h2 style={{ textAlign: "center" }}>END-USER LICENSE AGREEMENT</h2>
                    <h4 style={{ textAlign: "center" }}>Anihance Software</h4>
                    <br/>
                    <p>This End User License Agreement (“EULA”) is a legal agreement between you (either an individual or a company) (“Licensee”) and Moritz Schöpf (“Licensor”) for Software, owned by Moritz Schöpf and its third-party suppliers and licensors, that accompanies this EULA.  For purposes of this EULA, “Software” shall mean machine-readable instructions used by a processor to perform specific operations on Windows 10 Operating System.  BY USING THE SOFTWARE, LICENSEE ACKNOWLEDGES ACCEPTANCE OF THE TERMS OF THIS EULA. IF LICENSEE DOES NOT ACCEPT THESE TERMS, LICENSEE MAY NOT USE THE SOFTWARE.</p>
                    <p>This EULA sets out the basis on which the Licensor makes the Software available to you , by clicking "I Agree", downloading, accessing, installing, running or using the Anihance Software  (hereinafter referred to as the "Software") and documentation, you agree as such: (i.) that this EULA is a legally binding and valid agreement, (ii.) to abide by the terms and conditions of this EULA, and (iii.) to take all necessary steps to ensure that the Terms and Conditions of this EULA are not violated by any person or entity under your control or in your service. If you are using the software on behalf of an organization, you are agreeing to the terms and conditions of this EULA for that organization and promising that you have the authority to bind that organization to these terms. In that case, "you" and "your" also refers to that organization. If you do not agree to the terms of this EULA, do not click "I agree", download, access, install, run or use the software. This EULA contains Disclaimers of Warranties and Limitations of Liability and Exclusive Remedies. The provisions below form the essential basis of our agreement.</p>
                    <p>The Licensor reserves the right to change, modify, add or delete articles in this agreement at any time, in accordance with the procedures described herein.   Capitalized terms used in this EULA without definition shall have the meanings specified in these Terms.  In consideration of the mutual promises set forth herein, Licensee and Licensor hereby agree as follows:</p>
                    <h4>MODIFICATIONS TO SOFTWARE OFFERINGS</h4>
                    <p>The Licensor may change pricing for the Software, Subscriptions, services and Content. We may not provide price protection or refunds due to price reductions, promotional pricing, or any other changes to pricing for any reason.</p>
                    <p>You acknowledge and agree that Licensor may suspend or discontinue offering the Software; modify the Software; change or discontinue the Services available through the Software; change how we offer and operate such Services (e.g., to begin charging or increase a fee to access features or Content that we previously made available without charge); remove Content; impose limits on certain features or restrict your access to part or all of the Software, Services and Content; or make other changes in Licensor' Software and service offerings at its sole discretion and with or without notice to you. We will not be liable to you or any third party for taking any of these actions and we will not be limited to the remedies above if you violate these Terms. You further acknowledge and agree that even if a copy of the Software continues to reside on your device, after we make changes in our Software or services offerings, the Software may not work as it did prior to such action, and Licensor will have no liability to you or any third party as a result.</p>
                    <h4>PURPOSE</h4>
                    <p>The purpose and intent of this Software is to enhance Anime Videos in real time.  It uses deep-learning algorithms and works with many third party applications.</p>
                    <h4>OWNERSHIP</h4>
                    <p>Subject to the terms hereof and the licenses granted hereunder, Licensor will have and retain sole and exclusive ownership of, and all right, title and interest in, the Licensor Improvements, and Licensee shall have no right, license or interest therein.</p>
                    <p>Licensor reserves all rights not expressly granted to you. You must comply with all laws when using the Software as well as all applicable copyright, trademark or other legal notices or restrictions.  You will not: </p>
                    <ul>
                        <li>in whole or in part, copy, reproduce, translate, modify, adapt, create derivative works based on, or reverse engineer, derive source code from, disassemble, decompile, or otherwise attempt to discover the source code of, the Software, except to the extent required by applicable law;</li>
                        <li>install or use the Software on a computer or other device that is primarily used as a file server;</li>
                        <li>remove, obscure, or alter any copyright, trademark, logo, or other proprietary notices in or on the Software;</li>
                        <li>use any unauthorized third-party Software that intercepts, "mines," or otherwise collects information from or through the Software, unless authorized by Licensor in its sole discretion;</li>
                        <li>distribute, publish, display, perform, sell, rent, lease, sublicense, assign, transfer, or grant a security interest in your rights in the Software, or authorize all or any portion of the Software to be copied onto another user's computer except as permitted hereunder;</li>
                        <li>intercept, emulate, or redirect the communication protocols used by the Software in any way, for any purpose, or engage in any activity that interferes with or disrupts the Software or Licensor' or its vendors' servers or other infrastructure; or</li>
                        <li>facilitate, create, or maintain any unauthorized connection to the Software, including without limitation: (a) any connection to any unauthorized server that emulates, or attempts to emulate, Licensor or its vendors' servers; and (b) any connection using programs or tools not approved by Licensor in its sole discretion.</li>
                        <li>The Services and Content accessible through the Software are also protected by intellectual property and other laws in Germany and in other countries, and are subject to limitations on your use thereof, as set forth more fully in the Licensor Terms.</li>
                    </ul>
                    <h4>LICENSE GRANTS</h4>
                    <p>Licensor grants Licensee a non-exclusive, non-transferable license to use the Software and Documentation solely for its personal operations at the Location(s) and on the Designated Environment, and copy the Software and Documentation for archival or backup purposes only, provided that all titles, trademarks, and copyright, proprietary and restricted rights notices shall be reproduced in all such copies, and that all copies shall be subject to the terms of this Agreement.</p>
                    <p>Licensor reserves all rights not expressly granted by Licensor under this Agreement. The Software codes and documentation are protected by copyright and other intellectual property laws and   by international treaties.</p>
                    <p>The term of this Agreement is a perpetual, worldwide, nonexclusive, and nontransferable license to use the Software, solely to perform those functions defined in the Documentation, and subject to all limitations and restrictions contained herein. The Software may only be used on the hardware and software components, including client machines, servers, and internet-working devices within Licensee’s internal computer network at Licensee’s location. However, this agreement (including all of the licenses granted herein) will terminate automatically and immediately in the event of any material breach of its terms or conditions by a party if not corrected within 30 days of the breaching party’s receipt of a written notice describing the breach.</p>
                    <h4>LICENSE TYPES</h4>
                    <p>The Software is licensed, not sold, and no ownership right is conveyed to Licensee, irrespective of the use of terms in this EULA such as “purchase” or “sale”.  This EULA grants you the following rights:</p>
                    <p><b>Free Trial License:</b> Licensor grants to you a non-exclusive, non-transferable, Trial License for trial and evaluation of the licensed software for a period of Fourteen (14) days. This License begins upon registration of an Anihance account on the website and ends Fourteen (14) days thereafter ("Trial Period").   If you are not willing to use the Licensed Software, after the Evaluation Period, delete all the copies installed in your computer with immediate effect. Licensee is forbidden from using the Licensed Software for any other use or otherwise offering it for resale under the terms of this Section, Licensor retains all rights not specifically granted to you herein.</p>
                    <p><b>Perpetual License:</b> As part of your choosing perpetual license, Licensor grants you a <b>fee-bearing</b>, nonexclusive, non-transferable, perpetual, world-wide license to Use the Licensed Software including user documentation, updates and upgrades to which you are entitled as well as any plug-ins provided to you.</p>
                    <p><u>Note:</u> Licensee will be able to use the software on up to three (3) concurrent devices (Windows 10 PCs). If a fourth device is added, Licensee will have to remove a previously existing device from the website before gaining access.  That is, Licensee is restricted to use the software on only three (3) concurrently registered devices.</p>
                    <h4>LICENSE LIMITATIONS AND EXCLUSIONS</h4>
                    <p>The foregoing license does not allow you to use the Software on any device that you do not own or control, and you may not distribute or make the Software available over a network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell, redistribute or sublicense the Software. You may only use the Software as allowed by the Software's features and functionalities. You may not copy (except as expressly permitted by this License and the Usage Rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Software, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any Third Party Service included with the Software). Any attempt to do so is a violation of the rights of Licensor and its licensors. If you breach this restriction, you may be subject to prosecution and damages. The terms of this License will govern any upgrades and updates, unless accompanied by a separate license agreement, in which case, the terms of that separate license agreement will govern accordingly</p>
                    <h4>FEES</h4>
                    <p>The license granted hereunder is subject to Licensee’s timely payment of fees as specified in the Invoice issued by Licensor or by Licensor’s authorized reseller. If Licensee have not previously paid the license fee for the Software, then Licensee must pay the license fee within the period indicated in the applicable invoice or as otherwise provided in Licensor’s pricing terms as published on the Site. Failure to pay any license fees by the due date will result in the immediate termination of the license(s) granted under this EULA.</p>
                    <p>Licensee is required to pay any and all transaction taxes, regardless of how or who imposed on, which are taxes that arise as a result of the EULA except for taxes imposed on Licensor’s net income. These transaction taxes may include, but are not limited to, local, state, provincial, federal or foreign taxes, levies, duties or similar governmental assessments of any nature, including value added taxes, consumption taxes, goods and services taxes, GST/HST, excise, sales, use or similar taxes and any withholding taxes (together “Taxes”).</p>
                    <p>All amounts paid pursuant to this EULA are payable in full and without reduction for Taxes. If Licensor has a legal obligation to pay or collect Taxes for which Licensee is responsible under this section, the appropriate amount shall be invoiced to and paid by Licensee, unless Licensee provides Licensor with a valid tax exemption certificate authorized by the appropriate taxing authority. If Licensee is required by a governmental taxing authority to withhold an amount from any payment due hereunder and pay such amount to the governmental authority, the prices will be grossed-up so that the net payment equals the original price.</p>
                    <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                    <p>Licensee acknowledge and agree that the Software and all copyrights, patents, trademarks, trade secrets and other intellectual property rights associated therewith are, and shall remain, the property of Licensor. Furthermore, You acknowledge and agree that the source and object code of the Softwares and the format, directories, queries, algorithms, structure and organization of the Software are the intellectual property and proprietary and confidential information of the Company and its affiliates, licensors and suppliers. Except as expressly stated in this License, Licensee is not granted any intellectual property rights in or to the Software by implication, estoppel or other legal theory, and all rights in and to the Software not expressly granted in this License are hereby reserved and retained by Licensee.</p>
                    <p>You are not authorized to use the Licensee trademarks in any advertising, publicity or in any other commercial manner without the prior written consent of Licensee, which may be withheld for any or no reason.</p>
                    <h4>CONFIDENTIALITY</h4>
                    <p>This shall mean the Software, Documentation, Specifications, and terms and conditions of this Agreement. Licensee acknowledges the confidential and proprietary nature of the Confidential Information and agrees that it shall not reveal or disclose any Confidential Information for any purpose to any other person, firm, corporation, or other entity, other than Licensee’s employees with a need to know such Confidential Information to perform employment responsibilities consistent with Licensee’s rights under this Agreement. </p>
                    <p>Licensee shall safeguard and protect the Confidential Information from theft, piracy, or unauthorized access in a manner at least consistent with the protections Licensee uses to protect its own most confidential information. Licensee shall inform its employees of their obligations under this Agreement, and shall take such steps as may be reasonable in the circumstances, or as may be reasonably requested by Licensor, to prevent any unauthorized disclosure, copying or use of the Confidential Information.</p>
                    <p>Licensee acknowledges and agrees that in the event of the Licensee’s breach of this Agreement, Licensor will suffer irreparable injuries not compensated by money damages and therefore shall not have an adequate remedy at law. Accordingly, Licensor shall be entitled to a preliminary and final injunction without the necessity of posting any bond or undertaking in connection therewith to prevent any further breach of these confidentiality obligations or further unauthorized use of Confidential Information. This remedy is separate and apart from any other remedy Licensor may have.</p>
                    <p>Licensee shall notify Licensor immediately upon discovery of any prohibited use or disclosure of the Confidential Information, or any other breach of these confidentiality obligations by Licensee, and shall fully cooperate with Licensor to help Licensor regain possession of the Confidential Information and prevent the further prohibited use or disclosure of the Confidential Information.</p>
                    <h4>THIRD PARTY</h4>
                    <p>The Software may utilize or include third party software that is subject to open source and third party license terms (“Third Party Software”). Licensee acknowledge and agree that Licensee has right to use such Third Party Software as part of the Software is subject to and governed by the terms and conditions of the open source or third party license applicable to such Third Party Software, including, without limitation, any applicable acknowledgements, license terms and disclaimers contained therein. In the event of a conflict between the terms of this License and the terms of such open source or third party licenses, the terms of the open source or third party licenses shall control with regard to Licensee use of the relevant Third Party Software. In no event, shall the Software or components thereof be deemed to be “open source” or “publicly available” software.</p>
                    <p>The Licensed Software may contain software that originated with third party vendors and without limiting the general applicability of the other provisions of this Agreement, you agree that (a.) the title to any third party software incorporated in the Licensed Software shall remain with the third party that supplied the same; (b.) Licensee will not distribute any such third party software available with the Licensed Software, in any manner. Licensor disclaims any liability for the consequence of such actions by such third parties.</p>
                    <p>Licensee and Licensor acknowledge and agree that, in the event of a third party claim that the Software or Your possession or use of the Software infringes any third party’s intellectual property rights, Licensee (and not Licensor) will be responsible for the investigation, defense, settlement and discharge of any such claim of intellectual property infringement. You will however, promptly notify Licensor in writing of such a claim.</p>
                    <h4>SUPPORT</h4>
                    <p>The Licensor does not provide any support for the Software.  You may purchase technical support services for perpetual license by paying the then current maintenance and support fee.</p>
                    <p>Licensor encourages feedback from its customers. If you have any feedback regarding your purchase or use of the Software, please provide that feedback to Licensor support at support@anihance.com.</p>
                    <h4>DATA SECURITY AND PRIVACY</h4>
                    <p>The General Data Protection Regulation 2016/679 (GDPR) is a European Union law on data protection and privacy for individuals located within the European Union and the European Economic Area. You may need to comply with the GDPR; for further information, you should review seek your own professional advice, where necessary.</p>
                    <p>If the processing, by us, of any of the personal data of, or relating to, your Authorized Users, or customers or clients (Personal Data) is governed by the GDPR, the additional terms of Licensor' Data Processing Agreement (DPA) apply and form part of this Agreement. You warrant that by accessing, registering, installing, or using the Software, you have reviewed the DPA and you accept it. The DPA is available on the website and by request.</p>
                    <p>You acknowledge and agree that in collecting, holding and processing Personal Data through the Services, Licensor is acting as the data processor (as defined in the GDPR) for the purposes of the GDPR. You must obtain all necessary consents from the relevant individual to enable Licensor to collect, use, hold and process Personal Data in accordance with this Agreement and, if applicable, the DPA.</p>
                    <p>You acknowledge and agree that unless specifically requested through the user interface (for example - a field may be labeled with Email when requesting a user's Email), the Authorized Users will not submit any Personal Identifiable Information to the Software. When any Personal Information is requested, Licensor will take reasonable measures to protect the information submitted.</p>
                    <p>Licensor and its subsidiaries may periodically collect and use technical and related data concerning the Software You have licensed, including anonymous data about the Authorized user's usage of the Software. Licensor will use such data to facilitate maintenance and support with respect to the Software, to improve its Softwares and to provide further services or technologies to Licensee.</p>
                    <p>Licensor must ensure that Licensor’s use of all Data is at all times compliant with all applicable local, state, federal and international laws and regulations (“Laws”). Licensee represent and warrant that:</p>
                    <ul>
                        <li>Licensee have obtained all necessary rights, releases and permissions to provide all Licensee Data to Licensor and to grant the rights granted to Licensor in this Agreement and</li>
                        <li>Licensee Data and its transfer to and use by Licensor as authorized by Licensee under this Agreement do not violate any Laws (including without limitation those relating to export control and electronic communications) or rights of any third party, including without limitation any intellectual property rights, rights of privacy, or rights of publicity, and any use, collection, and disclosure authorized herein is not inconsistent with the terms of any applicable privacy policies. Licensor assumes no responsibility or liability for Licensee Data, and Licensee shall be solely responsible for Licensee Data and the consequences of using, disclosing, storing, or transmitting it.</li>
                    </ul>
                    <p>Licensee also acknowledges that Licensor is not acting as your Business Associate or subcontractor and that unless specifically specified by Licensor. Notwithstanding any other provision to the contrary, Licensor has no liability under this Agreement for Sensitive Data.</p>
                    <p>You will defend, indemnify and hold harmless Licensor from and against any loss, cost, liability or damage, including attorneys’ fees, for which Licensor becomes liable arising from or relating to any claim relating to Licensee Data, including but not limited to any claim brought by a third party alleging that Licensee Data, or Licensee use of the services provided by Licensor in breach of this Agreement, infringes or misappropriates the intellectual property rights of a third party or violates applicable law. This indemnification obligation is subject to your receiving</p>
                    <ul>
                        <li>prompt written notice of such claim with sufficient time for you to respond without prejudice;</li>
                        <li>the exclusive right to control and direct the investigation, defense, or settlement of such claim; and</li>
                        <li>all reasonable necessary cooperation of Licensor at your expense.</li>
                    </ul>
                    <h4>TERMINATION OF ACCOUNT</h4>
                    <p>You agree that we may for any reason, in our sole discretion and without notice, terminate your account. Grounds for such termination may include (i.) extended periods of inactivity, (ii.) violation of this Agreement, (iii.) fraudulent, harassing or abusive behavior, or (iv.) behavior that is harmful to other users, third parties, and the community ethos of our Software or our business interests. In the event that we terminate your account, you may not register for the Software again without our express permission. If you believe that any action has been taken against your account in error, please contact us at support@anihance.com.</p>
                    <p>In addition, if we believe, in our sole discretion, that a violation of this Agreement or any illegal or inappropriate behavior has occurred, we may take any other corrective action we deem appropriate. We reserve the right to investigate suspected violations of this Agreement or illegal and inappropriate behavior through the Software. Licensor will fully cooperate with any law enforcement investigation or court order requesting or directing us to disclose the identity, behavior or activities of anyone believed to have violated this Agreement or to have engaged in illegal behavior. Licensee may request termination of your account at any time and for any reason by sending an email to support@anihance.com.</p>
                    <p>Any suspension or termination of your account shall not affect your obligations to us under this Agreement (including but not limited to ownership, indemnification, any representations and warranties made by you, and limitation of liability), which by their sense and context are intended to survive such suspension or termination.</p>
                    <h4>COMPATIBILITY</h4>
                    <p>Licensor do not warrant that the Software will be compatible or interoperable with Licensee device or any other piece of hardware, Software, equipment or device installed on or used in connection with Licensee Device. Furthermore, You acknowledge that compatibility and interoperability problems can cause the performance of your Device to diminish or fail completely, and may result in permanent the damage to Licensee Device, loss of the data located on Your Device, and Corruption of the Software and files located on Licensee Device, Licensee acknowledge and agree that Licensor and our affiliates, partners, suppliers and licensors shall have no liability to Licensee for any losses suffered resulting from or arising in connection with compatibility or interoperability problems.</p>
                    <h4>UPDATES</h4>
                    <p>Licensor may make available to Licensee updates, upgrades, supplements and add-on components (if any) of the Software, including bug fixes, service upgrades (parts or whole), and updates, enhancements and feature improvements or deletion to any Software (including entirely new versions), (collectively "Update") after the date you obtain your initial copy of the Software. This EULA applies to all and any component of the Update, unless we provide other terms along with such Update.</p>
                    <h4>WARRANTY DISCLAIMER, LIMITATION OF LIABILITY</h4>
                    <p>YOU EXPRESSLY ACKNOWLEDGE THAT USE OF THE SOFTWARE IS AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE SOFTWARE IS SUPPLIED ON AN “AS IS” AND “AS AVAILABLE” BASIS. LICENSORS, PARTNERS AND ASSOCIATED SERVICE PROVIDERS DO NOT MAKE AND HEREBY DISCLAIM ANY GUARANTEES, CONDITIONS, WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY OR OTHER TERMS INCLUDING AS TO: </p>
                    <ul>
                        <li>ITS CONFORMITY, ACCURACY, CURRENTNESS, COMPLETENESS, RELIABILITY OR SECURITY;</li>
                        <li>ITS SUITABILITY FOR A PARTICULAR USE;</li>
                        <li>IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT;</li>
                        <li>ITS MARKET VALUE; OR</li>
                        <li>YOUR SATISFACTION.</li>
                    </ul>
                    <p>LICENSOR DOES NOT WARRANT THAT THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SOFTWARE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME ALL RESPONSIBILITY FOR SELECTING THE SOFTWARE TO ACHIEVE YOUR INTENDED RESULTS, AND FOR THE INSTALLATION OF, USE OF, AND RESULTS OBTAINED FROM THE SOFTWARE.</p>
                    <p>TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, IN NO EVENT WILL LICENSORS, PARTNERS AND ASSOCIATED SERVICE PROVIDERS BE LIABLE FOR LOSS OR DAMAGE SUFFERED IN CONNECTION WITH THE USE OF THE SOFTWARE OR ANY RELATED THIRD PARTY SERVICE. THIS INCLUDES WITHOUT LIMITATION (A.) ALL LOSSES OF ANY KIND, WHETHER IN TORT (INCLUDING FOR NEGLIGENCE OR BREACH OF STATUTORY DUTY), CONTRACT, MISREPRESENTATION (WHETHER INNOCENT OR NEGLIGENT) OR OTHERWISE, (B.) DIRECT LOSS; (C.) ACCIDENTAL LOSS, (D.) INCIDENTAL LOSS, (E.) CONSEQUENTIAL LOSS, AND (F.) INDIRECT LOSS.</p>
                    <p>NOTWITHSTANDING THE AFOREMENTIONED LIMITATIONS OF LIABILITY, YOUR SOLE REMEDY IN THE EVENT OF A DISPUTE WITH LICENSOR OR ITS LICENSORS, PARTNERS AND ASSOCIATED SERVICE PROVIDERS IS TO CEASE TO USE THE SOFTWARE; AND IF APPLICABLE, SEEK DAMAGES FOR YOUR LOSSES. IN NO EVENT LICENSOR, ITS AFFILIATES, LICENSORS, PARTNERS AND ASSOCIATED SERVICE PROVIDERS BE LIABLE FOR DAMAGES IN EXCESS OF ANY AMOUNT YOU HAVE PAID TO LICENSOR FOR THE SOFTWARE DURING THE TWELVE (12) MONTHS IMMEDIATELY PRIOR TO THE TIME YOUR CAUSE OF ACTION AROSE.</p>
                    <p>NOTHING IN THIS SECTION SHALL AFFECT LICENSOR’S LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM LICENSOR’S NEGLIGENCE, FOR FRAUD OR FRAUDULENT MISREPRESENTATION, NOR ANY OTHER LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    <p>FOR PURPOSES OF THIS SECTION, THE COMPANY’S LICENSORS, PARTNERS AND ASSOCIATED SERVICE PROVIDERS ARE THIRD PARTY BENEFICIARIES TO THE LIMITATIONS OF LIABILITY SPECIFIED HEREIN AND THEY MAY ENFORCE THIS EULA AGAINST YOU.</p>
                    <p>Notwithstanding anything to the contrary in this Section, any and all warranties under this License Agreement are void if User has made changes to the Software or has permitted any changes to be made other than by or with the express, written approval of Licensor.</p>
                    <h4>INDEMNITY</h4>
                    <p>You will indemnify, defend, and hold Licensor and all of its officers, directors, owners, employees, agents, information providers, service providers, vendors, contractors, affiliates, partners, and licensors (collectively, the "Anihance Parties") harmless from and against any and all liability, losses, costs, and expenses (including attorneys' fees) incurred by any of the Companies Parties in connection with any claim, including, but not limited to, claims for defamation, violation of rights of publicity or privacy, copyright infringement, or trademark infringement arising out of: your use of the Software; any use or alleged use of your account or your passwords by any person, whether or not authorized by you; your connection to the Software’s or its vendors' servers; Licensee violation of this Agreement; or your violation of the rights of any other person or entity. Licensor reserves the right, at its own expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Moritz Schöpf and you will cooperate with Company's defense of these claims.</p>
                    <h4>GOVERNING LAW / DISPUTE RESOLUTION</h4>
                    <p>The parties agree that this EULA and the interpretation of its terms shall be governed by and construed in accordance with the laws of Germany and subject to the exclusive jurisdiction of the courts located in Germany. If you reside in a country where the laws of the stated jurisdiction are excluded from applying, your country’s laws will apply to such disputes related to these terms. Otherwise, you agree that the laws of Germany to govern this License Agreement, as well as any claim, dispute, action or issue that might arise out of or in connection with it excluding their conflicts of laws principles. Any action or proceeding relating to this License must be brought to the District Court of Munich, Germany and each party irrevocably submits to the jurisdiction and venue of any such court in any such claim or dispute, except that Licensor may seek injunctive relief in any court having jurisdiction to protect its confidential information or intellectual property.</p>
                    <p>The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from Software to this License Agreement.  Except as may be required by law, neither a party nor its representatives may disclose the existence, content, or results of any arbitration hereunder without the prior written consent of both parties. The terms of the United Nations Convention on Contracts for the Sale of Goods do not apply to this EULA.</p>
                    <h4>AUDIT</h4>
                    <p>Licensor has the right to audit your Use of the Licensed Software by providing at least seven (7) days prior written notice of its intention to conduct such an audit at your facilities during normal business hours.</p>
                    <h4>MISCELLANEOUS</h4>
                    <p><b>Validity:</b> If any part of this Agreement is held to be illegal or unenforceable, the validity or enforceability of the remainder of this Agreement shall not be affected.</p>
                    <p><b>Binding Agreement:</b> This Agreement will be binding upon and inure to the benefit of the parties hereto, their respective successors and assigns. Licensee may not assign its rights or obligations under this Agreement without the prior written consent of Licensor.</p>
                    <p><b>No Waiver:</b> Failure by either party to exercise any right or remedy under this Agreement does not signify acceptance of the event-giving rise to such right or remedy.  No failure or delay in enforcing any right or exercising any remedy will be deemed a waiver of any right or remedy.</p>
                    <p><b>Severability and Reformation:</b> Each provision of this License Agreement is a separately enforceable provision. If any provision of this Agreement is determined to be or becomes unenforceable or illegal, such provision shall be reformed to the minimum extent necessary in order for this License Agreement to remain in effect in accordance with its terms as modified by such reformation.</p>
                    <p><b>Attorneys’ Fees:</b> Licensee shall be responsible for Licensor’s reasonable attorneys’ fees associated with the enforcement of the terms of this Agreement or the collection of any amounts due under this Agreement.</p>
                    <p><b>Survival:</b> Sections 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, and 13.0 shall survive the termination of this Agreement for any reason.</p>
                    <h4>ASSIGNMENT</h4>
                    <p>Licensee may assign this EULA to succeed parties in the case of a merger, acquisition or change of control; provided, however, that in each case, (a.) Licensor is notified in writing within ninety (90) days of such assignment, (b.) the assignee agrees to be bound by the terms and conditions contained in this EULA and (c.) upon such assignment the previous assignee makes no further use of the Software(s) licensed under this EULA. Licensor may assign its rights and obligation under this EULA without consent of Licensee. Any permitted assignee shall be bound by the terms and conditions of this EULA.</p>
                    <h4>NOTICES</h4>
                    <p>Any notice given to a party under or in connection with this contract shall be in writing and shall be:</p>
                    <ul>
                        <li>delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case); or</li>
                        <li>sent by fax or email to its main fax number or email address.</li>
                    </ul>
                    <p>Any notice shall be deemed to have been received:</p>
                    <ul>
                        <li>if delivered by hand, on signature of a delivery receipt;</li>
                        <li>if sent by pre-paid first-class post or other next working day delivery service,</li>
                        <li>sent by fax or email to its main fax number or email address.</li>
                    </ul>
                    <p>This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any arbitration or other method of dispute resolution. For the purposes of this clause, "writing" shall not include Email.</p>
                    <h4>ENTIRE AGREEMENT</h4>
                    <p>This Agreement and other terms and policies comprise the entire agreement between the parties regarding the subject matter hereof and supersedes and merges all prior proposals, understandings, and all other agreements, oral, and written between the parties relating to the Agreement.</p>
                    <h4>CONTACT US</h4>
                    <p>You may Contact the Developer at:</p>
                    <p>Moritz Schöpf<br/>Krüner Str. 126, 81377 Munich, Germany.<br/>Email: moritz.schoepf@anihance.com</p>
                    <p><u>Note:</u> Some of the headings in these terms of agreement might not fully explain their contents and purpose, so therefore; we advise that you review these terms properly before using the Anihance Software.</p>
                    <p>If you have any questions, claims, or complaints, you should notify us through the contact or help feature of the relevant Service.</p>
                    <p><b>Revised and Updated: December, 2020</b></p>
                </Container>
                </div>
            );
        }
    }
  }