import React from "react";
import ReactCompareImage from "react-compare-image";
import { Container, Button, Image, Row, Col } from "react-bootstrap";
import { locale } from './Locale';
import { Helmet } from "react-helmet";

export class HomeComponent extends React.Component {
    
  render() {
    return (
      <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
        <Helmet>
          <title>Anihance - {locale.get("About")}</title>
          <meta name="description" content={locale.get("HomeWhatIsAnihanceNote")}/>
        </Helmet>
        <Container>
          <Row>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeWhatIsAnihance")}</h2>
              <h5>{locale.get("HomeWhatIsAnihanceNote")}</h5>
            </Col>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeWhatDoesItDo")}</h2>
              <h5>{locale.get("HomeWhatDoesItDoNote")}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-grid gap-2">
                <Button variant="primary" size="lg" href="/download">
                  {locale.get("Download")}
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <Button variant="primary" size="lg" href="/register">
                  {locale.get("TryForFree")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeBefore")}</h2>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Image style={{ minHeight: 400 }} src="./Example Before.png"/>
              </Container>
            </Col>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeAfter")}</h2>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Image style={{ minHeight: 400 }} src="./Example After.png"/>
              </Container>
            </Col>
          </Row>
          <Row style={{ padding: 20 }}>
            <Col>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeComparison")}</h2>
              <h5 style={{ textAlign: "center" }}>{locale.get("HomeComparisonNote")}</h5>
            </Col>
          </Row>
          <ReactCompareImage
            leftImage="Compare After.png"
            leftImageLabel="Anihance"
            rightImage="Compare Before.png"
            rightImageLabel={locale.get("HomeBefore")}
            handleSize="80"
            sliderLineColor="#000000"
            sliderLineWidth="5"
          />
          <Row style={{ paddingTop: 40 }}>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeUseCases")}</h2>
              <h5>{locale.get("HomeUseCasesNote")}</h5>
              <br/>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeHowDoesItWork")}</h2>
              <h5>{locale.get("HomeHowDoesItWorkNote")}</h5>
            </Col>
            <Col style={{ padding: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Image src={locale.get("FilenameScreenshotControl")}/>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeHowMuchIsIt")}</h2>
              <h5>{locale.get("HomeHowMuchIsItNote")}</h5>
              <div className="d-grid gap-2">
                <Button style={{ marginTop: 20 }} variant="primary" size="lg" href="/register">
                  {locale.get("TryForFree")}
                </Button>
              </div>
            </Col>
            <Col style={{ padding: 20 }}>
              <h2 style={{ textAlign: "center" }}>{locale.get("HomeQuestions")}</h2>
              {locale.get("HomeQuestionsNote")}
            </Col>
          </Row>
        </Container>
        </div>
    );
  }
}