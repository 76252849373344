import React from 'react';
import { Container, Form, Button, ListGroup, Alert, Badge, Table } from "react-bootstrap";
import { locale } from "./Locale";
import { DeviceComponent } from "./DeviceComponent";
import { ChangePasswordComponent } from "./ChangePasswordComponent";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Helmet } from "react-helmet";

export class AccountComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.data = {
            email: "",
            password: "",
            submitButtonDisabled: true,
            showLoginFailAlert: false,
            loginFailAlertText: "",
            showPaymentNotification : false,
            paymentWaitComponent: null,
            paymentNotificationText: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.payPalCreateOrder = this.payPalCreateOrder.bind(this);
        this.payPalOnApprove = this.payPalOnApprove.bind(this);
        this.payPalOnCancel = this.payPalOnCancel.bind(this);
        this.payPalOnError = this.payPalOnError.bind(this);
    }

    componentDidMount() {
        // Refresh the information when logged in
        if (sessionStorage.getItem("AccountData") != null) {
            
            let parameters = new FormData();
            parameters.append("Email", sessionStorage.getItem("Email"));
            parameters.append("Password", sessionStorage.getItem("Password"));
            
            fetch("https://api.anihance.com/account.php", {
            method : "POST",
            body : parameters
            })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.success && responseJson.verified) {
                    // Update the account information locally
                    sessionStorage.setItem("AccountData", JSON.stringify(responseJson));
                    this.forceUpdate();
                }
            });
        }
    }

    handleChange(event) {
        // There is no need to perform any ASCII checks here because login will just fail
        this.data[event.target.id] = event.target.value;
        
        // Check if all the input is valid
        this.data.submitButtonDisabled = false;

        // Perform checks and validation
        if (this.data.email.length === 0 || this.data.email.length > 128) {
            this.data.submitButtonDisabled = true;
        } else if (this.data.password.length < 8) {
            this.data.submitButtonDisabled = true;
        }

        this.setState(this.data);
    }

    handleSubmit(event) {
        let parameters = new FormData();
        parameters.append("Email", this.data.email);
        parameters.append("Password", this.data.password);

        // Call the PHP script for login and account information
        fetch("https://api.anihance.com/account.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success) {
                if (responseJson.verified) {
                    // Store the retrieved login information locally
                    // Also refresh the page so that the navbar and content is updated
                    sessionStorage.setItem("AccountData", JSON.stringify(responseJson));
                    sessionStorage.setItem("Email", this.data.email);
                    sessionStorage.setItem("Password", this.data.password);
                    window.location.reload();
                } else {
                    this.data.showLoginFailAlert = true;
                    this.data.loginFailAlertText = locale.get("VerifyAccount");
                    this.setState(this.data);
                }
            } else {
                this.data.showLoginFailAlert = true;
                this.data.loginFailAlertText = locale.get("LoginFail");
                this.setState(this.data);
            }
        })
        .catch(error => {
            this.data.showLoginFailAlert = true;
            this.data.loginFailAlertText = locale.get("LoginFail")
            this.setState(this.data);
        });

        event.preventDefault();
    }

    payPalCreateOrder(data, actions) {        
        return actions.order.create({
            purchase_units: [
                {
                    description: locale.get("AnihanceLicense"),
                    amount: {
                        currency_code: "EUR",
                        value: 7.99
                    }
                }
            ]
        });
    }

    payPalOnApprove(data, actions) {
        // Show a please wait message
        this.data.paymentWaitComponent = <h2>{locale.get("PaymentWait")}</h2>;
        this.setState(this.data);

        actions.order.capture().then(details => {
            let parameters = new FormData();
            parameters.append("Email", sessionStorage.getItem("Email"));
            parameters.append("Password", sessionStorage.getItem("Password"));
            parameters.append("OrderID", data.orderID);

            // Call the PHP script that validates the payment and updates the license
            fetch("https://api.anihance.com/payment.php", {
                method : "POST",
                body : parameters
            })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    this.data.paymentNotificationText = locale.get("PaymentApprove");
                    this.data.showPaymentNotification = true;
                    this.setState(this.data);
                } else {
                    this.data.paymentNotificationText = locale.get("LicenseApproveError");
                    this.data.showPaymentNotification = true;
                    this.setState(this.data);
                }
            })
            .catch(error => {
                this.data.paymentNotificationText = locale.get("LicenseApproveError");
                this.data.showPaymentNotification = true;
                this.setState(this.data);
            });
        });

        // Remove the please wait message
        this.data.paymentWaitComponent = null;
        this.setState(this.data);
    }

    payPalOnCancel(data) {
        this.data.showPaymentNotification = true;
        this.data.paymentNotificationText = locale.get("PaymentCancel");
        this.setState(this.data);
    }

    payPalOnError(error) {
        this.data.showPaymentNotification = true;
        this.data.paymentNotificationText = locale.get("PaymentError");
        this.setState(this.data);
    }

    render() {
        let accountData = sessionStorage.getItem("AccountData");

        // Show login failed message
        let loginFailAlert = null;

        if (this.data.showLoginFailAlert) {
            loginFailAlert = (
                <Alert style={{ marginTop: 20 }} variant="danger" onClick={() => { this.data.showLoginFailAlert = false; this.setState(this.data); }} dismissible>
                    {this.data.loginFailAlertText}
                </Alert>
            );
        }

        // Show the login window if not logged in
        if (accountData === null) {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("Login")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 400 }}>
                    <h2 style={{textAlign: "center"}}>{locale.get("Login")}</h2>
                    <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>{locale.get("Email")}</Form.Label>
                        <Form.Control type="email" placeholder={locale.get("Email")} values={this.data.email} onChange={this.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>{locale.get("Password")}</Form.Label>
                        <Form.Control type="password" placeholder={locale.get("Password")} value={this.data.password} onChange={this.handleChange}/>
                    </Form.Group>
                    {loginFailAlert}
                    <div className="d-grid gap-2">
                    <Button disabled={this.data.submitButtonDisabled} style={{ marginTop: 20 }} variant="primary" size="lg" type="submit" onSubmit={this.handleSubmit}>
                        {locale.get("Login")}
                    </Button>
                    </div>
                    </Form>
                    <div style={{ textAlign: "center", marginTop: 50}}>
                    <a href="/reset-password">{locale.get("ResetPassword")}</a>
                    </div>
                </Container>
                </div>
            );
        }

        accountData = JSON.parse(accountData);

        // Construct the license component
        let license = (
            <Badge variant="danger">{locale.get("LicenseExpired")}</Badge>
        );

        if (accountData.license) {
            license = (
                <Badge variant="success">{locale.get("PaidLicense")}</Badge>
            );
        } else if (accountData.trial) {
            license = (
                <div>
                <Badge variant="warning">{locale.get("FreeTrial")}</Badge>
                <br/>
                <small>{accountData.trialDaysLeft} {locale.get("DaysLeft")}</small>
                </div>
            );
        }

        let buy = null;

        // PayPal buttons are shown here
        if (!accountData.license) {
            let payPal = null;
            let paymentNotification = null;

            // Either show the PayPal buttons or a notification text
            if (this.data.showPaymentNotification) {
                paymentNotification = (
                    <div>
                        <br/>
                        <b>{this.data.paymentNotificationText}</b>
                    </div>
                );
            } else {
                payPal = (
                    <div>
                    {this.data.paymentWaitComponent}
                    <PayPalButtons
                        createOrder={(data, actions) => this.payPalCreateOrder(data, actions)}
                        onApprove={(data, actions) => this.payPalOnApprove(data, actions)}
                        onCancel={(data) => this.payPalOnCancel(data)}
                        onError={(error) => this.payPalOnError(error)}
                    />
                    </div>
                );
            }
            
            // Paypal will send the money to the account with this client id on developer.paypal.com
            buy = (
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ textAlign: "center", paddingTop: 80 }}>{locale.get("Buy")}</h2>
                    {locale.get("BuyNote")}
                    <div style={{ padding: 10 }}/>
                    <PayPalScriptProvider options={{ "currency": "EUR", "client-id": "AfZvRmatGpfxuQ3U3oQksLWNsR491zQUSiG9brm5J_1LdOBsELXYQVWBG887Z5QEwgDcsZIGA46agv22" }}>
                        {paymentNotification}
                        {payPal}
                    </PayPalScriptProvider>
                </div>
            );
        }

        // Construct the device list component
        let deviceList = (
            <div style={{paddingTop: 20, textAlign: "center"}}>
                {locale.get("DeviceListEmpty")}
            </div>
        );

        if (accountData.devices.length > 0) {
            deviceList = (
                <ListGroup style={{ paddingTop: 20 }}>
                    { accountData.devices.map(device => <DeviceComponent key={device.DeviceID} device={device}/>)}
                </ListGroup>
            );
        }

        // This is shown after logging in
        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Container fluid="sm" style={{ maxWidth: 600 }}>
                    <h2 style={{ textAlign: "center" }}>{locale.get("YourAccount")}</h2>
                    <Table size="sm" striped bordered responsive>
                        <tbody>
                            <tr>
                                <td>{locale.get("Username")}</td>
                                <td>{accountData.username}</td>
                            </tr>
                            <tr>
                                <td>{locale.get("Email")}</td>
                                <td>{sessionStorage.getItem("Email")}</td>
                            </tr>
                            <tr>
                                <td>{locale.get("License")}</td>
                                <td>{license}</td>
                            </tr>
                        </tbody>
                    </Table>
                    {buy}
                    <h2 style={{ textAlign: "center", paddingTop: 80 }}>{locale.get("DeviceList")}</h2>
                    {locale.get("DeviceListNote")}
                    {deviceList}
                    <Container fluid="sm" style={{ paddingTop: 80, maxWidth: 400 }}>
                        <ChangePasswordComponent/>
                    </Container>
                </Container>
            </div>
        );
    }
  }