import React from 'react';
import { Button, ListGroup } from "react-bootstrap";
import { locale } from "./Locale";

export class DeviceComponent extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        let accountData = JSON.parse(sessionStorage.getItem("AccountData"));

        let parameters = new FormData();
        parameters.append("UserID", accountData.userID);
        parameters.append("DeviceID", this.props.device.DeviceID);

        // Call the PHP script
        fetch("https://api.anihance.com/remove-device.php", {
            method : "POST",
            body : parameters
        })
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.success === true) {
                window.location.reload();
            } else {
                alert(locale.get("ErrorRequest"));
            }
        }).catch(error => {
            alert(locale.get("ErrorRequest"));
        });

        event.preventDefault();
    }

    render() {
        return (
            <ListGroup.Item>
                {this.props.device.DeviceName}
                <br/>
                <small style={{ wordBreak: "break-all" }}>{this.props.device.DeviceID}</small>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginTop: 10}} variant="outline-danger" onClick={this.handleSubmit}>{locale.get("RemoveDevice")}</Button>
                </div>
            </ListGroup.Item>
        );
    }
  }