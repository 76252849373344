import React from "react";
import { locale } from "./Locale";

export class TranslateComponent extends React.Component {

    // This component just replaces the current language with the given one and goes to the home page
    componentDidMount() {
        locale.setLanguage(this.props.language);
        window.location.replace("/");
    }

    render() {
        return null;
    }
}