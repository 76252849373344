import React from 'react';
import { Container } from "react-bootstrap";
import { locale, languageEnum } from './Locale';
import cookie from "react-cookies";
import { Helmet } from "react-helmet";

export class PrivacyPolicyComponent extends React.Component {
    render() {
        let language = parseInt(cookie.load("Language"));

        let pageNotTranslated = null;
        
        // Show a warning if the page has not been translated yet (or will not be)
        if (language === languageEnum.Korean || language === languageEnum.Japanese) {
            pageNotTranslated = <p style={{textAlign: "center" }}>{locale.get("PageNotTranslated")}</p>;
        }

        if (language === languageEnum.German) {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("PrivacyPolicy")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 900 }}>
                    {pageNotTranslated}
                    <h2>Datenschutzerklärung</h2>
                    <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                    <p>Moritz Schöpf<br/>Krüner Str. 126<br/>81377 München<br/>Deutschland</p>
                    <h3>Ihre Betroffenenrechte</h3>
                    <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
                    <ul>
                        <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                        <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                        <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
                    </ul>
                    <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
                    <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
                    <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a></p>
                    <br/>
                    <h3>Erfassung allgemeiner Informationen beim Besuch unserer Website</h3>
                    <h4>Art und Zweck der Verarbeitung:</h4>
                    <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.</p>
                    <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
                    <ul>
                        <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                        <li>zur Optimierung unserer Website.</li>
                    </ul>
                    <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
                    <h4>Rechtsgrundlage und berechtigtes Interesse:</h4>
                    <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
                    <h4>Empfänger:</h4>
                    <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
                    <h4>Speicherdauer:</h4>
                    <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.</p>
                    <p>Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
                    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                    <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.</p>
                    <br/>
                    <h3>Cookies</h3>
                    <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen.</p>
                    <p>Sie können einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
                    <ul>
                        <li>Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a></li>
                        <li>Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                        <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de">https://support.google.com/accounts/answer/61416?hl=de</a></li>
                        <li>Opera: <a href="https://www.opera.com/de/help">https://www.opera.com/de/help</a></li>
                        <li>Safari: <a href="https://support.apple.com/kb/PH17191">https://support.apple.com/kb/PH17191</a></li>
                    </ul>
                    <h4>Speicherdauer und eingesetzte Cookies:</h4>
                    <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen:</p>
                    <ul>
                        <li>Status Ihrer Zustimmung zu Cookies, Speicherdauer: 1 Jahr</li>
                        <li>Übernahme von Spracheinstellungen, Speicherdauer: 1 Jahr</li>
                        <li>Verwaltung Ihres Benutzerkontos, Speicherdauer: bis zum Ende Ihrer Sitzung</li>
                    </ul>
                    <br/>
                    <h3>Technisch notwendige Cookies</h3>
                    <h4>Art und Zweck der Verarbeitung:</h4>
                    <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.</p>
                    <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
                    <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                    <ul>
                        <li>Status Ihrer Zustimmung zu Cookies, Speicherdauer: 1 Jahr</li>
                        <li>Übernahme von Spracheinstellungen, Speicherdauer: 1 Jahr</li>
                        <li>Verwaltung Ihres Benutzerkontos, Speicherdauer: bis zum Ende Ihrer Sitzung</li>
                    </ul>
                    <h4>Rechtsgrundlage und berechtigtes Interesse:</h4>
                    <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung unserer Website.</p>
                    <h4>Empfänger:</h4>
                    <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
                    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                    <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.</p>
                    <h4>Widerspruch</h4>
                    <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
                    <br/>
                    <h3>Registrierung auf unserer Website</h3>
                    <h4>Art und Zweck der Verarbeitung:</h4>
                    <p>Für die Registrierung auf unserer Website benötigen wir einige personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden.</p>
                    <p>Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich.</p>
                    <h4>Rechtsgrundlage:</h4>
                    <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
                    <h4>Empfänger:</h4>
                    <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
                    <h4>Speicherdauer:</h4>
                    <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt.</p>
                    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte gewähren.</p>
                    <br/>
                    <h3>Erbringung kostenpflichtiger Leistungen</h3>
                    <h4>Art und Zweck der Verarbeitung:</h4>
                    <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu können.</p>
                    <h4>Rechtsgrundlage:</h4>
                    <p>Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.</p>
                    <h4>Empfänger:</h4>
                    <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                    <h4>Speicherdauer:</h4>
                    <p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und steuerrechtlichen Anforderungen.</p>
                    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gewähren.</p>
                    <br/>
                    <h3>Kontaktformular</h3>
                    <h4>Art und Zweck der Verarbeitung:</h4>
                    <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
                    <h4>Rechtsgrundlage:</h4>
                    <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
                    <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
                    <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
                    <h4>Empfänger:</h4>
                    <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                    <h4>Speicherdauer:</h4>
                    <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
                    <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.</p>
                    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                    <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
                    <br/>
                    <h3>SSL-Verschlüsselung</h3>
                    <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
                    <br/>
                    <h3>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h3>
                    <h4>Einzelfallbezogenes Widerspruchsrecht</h4>
                    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
                    <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                    <h4>Empfänger eines Widerspruchs</h4>
                    <p>E-Mail: support@anihance.com</p>
                    <br/>
                    <h3>Änderung unserer Datenschutzbestimmungen</h3>
                    <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
                    <br/>
                    <h3>Fragen an den Datenschutzbeauftragten</h3>
                    <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
                    <p>E-Mail: moritz.schoepf@anihance.com</p>
                    <p>Die Datenschutzerklärung wurde mithilfe der <a href="https://www.activemind.de/">activeMind AG</a> erstellt, den Experten für externe Datenschutzbeauftragte (Version #2020-09-30).</p>
                </Container>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
                <Helmet>
                    <title>Anihance - {locale.get("PrivacyPolicy")}</title>
                </Helmet>
                <Container fluid="sm" style={{ maxWidth: 900 }}>
                    {pageNotTranslated}
                    <h2>Privacy Policy</h2>
                    <p>The person responsible within the meaning of the data protection laws, in particular the EU data protection basic regulation (DSGVO), is:</p>
                    <p>Moritz Schöpf<br/>Krüner Str. 126<br/>81377 Munich<br/>Germany</p>
                    <h3>Your data subject rights</h3>
                    <p>You can exercise the following rights at any time by contacting our data protection officer:</p>
                    <ul>
                        <li>Receiving information about your data stored by us and its processing (Art. 15 DSGVO),</li>
                        <li>Correcting incorrect personal data (Art. 16 DSGVO),</li>
                        <li>Deletion of your data stored by us (Art. 17 DSGVO),</li>
                        <li>Restriction of data processing, provided that we are not yet allowed to delete your data due to legal obligations (Art. 18 DSGVO),</li>
                        <li>Opposition to the processing of your data by us (Art. 21 DSGVO) and</li>
                        <li>Data transferability, provided you have consented to data processing or have concluded a contract with us (Art. 20 DSGVO).</li>
                    </ul>
                    <p>If you have given us your consent, you can revoke it at any time with effect for the future.</p>
                    <p>You may at any time submit a complaint to a supervisory authority, e.g. to the competent supervisory authority of the federal state of your residence or to the authority responsible for us as the responsible body.</p>
                    <p>A list of the regulatory authorities (for the non-public sector) with their addresses can be found at: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a></p>
                    <br/>
                    <h3>Collection of general information when visiting our website</h3>
                    <h4>Nature and purpose of the processing:</h4>
                    <p>When you access our website, i.e. when you do not register or otherwise submit information, information of a general nature is automatically collected. This information (server log files) includes, for example, the type of web browser, the operating system used, the domain name of your internet service provider, your IP address and similar.</p>
                    <p>They are processed in particular for the following purposes:</p>
                    <ul>
                        <li>Ensuring that the website can be connected without problems,</li>
                        <li>Ensuring a smooth use of our website,</li>
                        <li>Evaluation of system safety and stability, and</li>
                        <li>to optimise our website.</li>
                    </ul>
                    <p>We do not use your data to draw conclusions about your person. Information of this kind will be statistically evaluated by us, anonymously if necessary, in order to optimise our Internet presence and the technology behind it.</p>
                    <h4>Legal basis and legitimate interest:</h4>
                    <p>Processing is carried out in accordance with Art. 6 Para. 1 letter f DSGVO on the basis of our justified interest in improving the stability and functionality of our website.</p>
                    <h4>Receiver:</h4>
                    <p>Recipients of the data may be technical service providers who act as contract processors for the operation and maintenance of our website.</p>
                    <h4>Storage duration:</h4>
                    <p>The data will be deleted as soon as they are no longer required for the purpose of the collection. This is generally the case for the data used to provide the website, when the respective session has ended.</p>
                    <p>If the data is stored in log files, this is the case after 14 days at the latest. Storage beyond this period is possible. In this case, the IP addresses of the users are anonymised, so that an allocation of the calling client is no longer possible.</p>
                    <h4>Provision is mandatory or necessary:</h4>
                    <p>The provision of the aforementioned personal data is not required by law or contract. Without the IP address, however, the service and functionality of our website cannot be guaranteed. In addition, individual services may not be available or may be restricted. For this reason an objection is excluded.</p>
                    <br/>
                    <h3>Cookies</h3>
                    <p>Like many other websites, we also use so-called "cookies". Cookies are small text files that are stored on your end device (laptop, tablet, smartphone or similar) when you visit our website.</p>
                    <p>You can delete individual cookies or the entire cookie inventory. You will also receive information and instructions on how to delete these cookies or block their storage in advance. Depending on the provider of your browser, you will find the necessary information under the following links:</p>
                    <ul>
                        <li>Mozilla Firefox: <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a></li>
                        <li>Internet Explorer: <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a></li>
                        <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=en">https://support.google.com/accounts/answer/61416?hl=en</a></li>
                        <li>Opera: <a href="https://www.help.opera.com/">https://www.help.opera.com/</a></li>
                        <li>Safari: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
                    </ul>
                    <h4>Storage duration and cookies used:</h4>
                    <p>If you allow us to use cookies through your browser settings or consent, the following cookies may be used on our websites:</p>
                    <ul>
                        <li>Status of your consent to cookies, storage period: 1 year</li>
                        <li>State of language settings, storage period: 1 year</li>
                        <li>Management of your user account, storage period: until the end of your session</li>
                    </ul>
                    <br/>
                    <h3>Technically necessary cookies</h3>
                    <h4>Nature and purpose of the processing:</h4>
                    <p>We use cookies to make our website more user-friendly. Some elements of our website require the calling browser to be able to be identified even after a page change.</p>
                    <p>The purpose of using technically necessary cookies is to simplify the use of websites for users. Some functions of our website cannot be offered without the use of cookies. For these, it is necessary that the browser is recognised even after a page change.</p>
                    <p>We require cookies for the following applications:</p>
                    <ul>
                        <li>Status of your consent to cookies, storage period: 1 year</li>
                        <li>State of language settings, storage period: 1 year</li>
                        <li>Management of your user account, storage period: until the end of your session</li>
                    </ul>
                    <h4>Legal basis and legitimate interest:</h4>
                    <p>Processing is carried out in accordance with Art. 6 Para. 1 letter f DSGVO on the basis of our justified interest in a user-friendly design of our website.</p>
                    <h4>Receiver:</h4>
                    <p>Recipients of the data may be technical service providers who act as contract processors for the operation and maintenance of our website.</p>
                    <h4>Provision is mandatory or necessary:</h4>
                    <p>The provision of the aforementioned personal data is not required by law or contract. Without this data, however, the service and functionality of our website cannot be guaranteed. In addition, individual services may not be available or may be restricted.</p>
                    <h4>Opposition</h4>
                    <p>Please read the information on your right of objection under Art. 21 DSGVO below.</p>
                    <br/>
                    <h3>Registration on our website</h3>
                    <h4>Nature and purpose of the processing:</h4>
                    <p>To register on our website, we require some personal data, which are transmitted to us via an input mask.</p>
                    <p>Your registration is required for the provision of certain content and services on our website.</p>
                    <h4>Legal basis:</h4>
                    <p>The data entered during registration is processed on the basis of the user's consent (Art. 6 para. 1 letter a DSGVO).</p>
                    <h4>Receiver:</h4>
                    <p>Recipients of the data may be technical service providers who act as contract processors for the operation and maintenance of our website.</p>
                    <h4>Storage duration:</h4>
                    <p>Data will only be processed in this context as long as the corresponding consent has been obtained.</p>
                    <h4>Provision is mandatory or necessary:</h4>
                    <p>The provision of your personal data is voluntary, solely based on your consent. Without the provision of your personal data, we cannot grant you access to our offered contents.</p>
                    <br/>
                    <h3>Provision of chargeable services</h3>
                    <h4>Nature and purpose of the processing:</h4>
                    <p>In order to provide services that are subject to a charge, we ask for additional data, such as payment details, in order to be able to execute your order.</p>
                    <h4>Legal basis:</h4>
                    <p>The processing of the data required for the conclusion of the contract is based on Art. 6 para. 1 letter b DSGVO.</p>
                    <h4>Receiver:</h4>
                    <p>Recipients of the data may be contract processors.</p>
                    <h4>Storage duration:</h4>
                    <p>We store this data in our systems until the legal retention periods have expired. These are generally 6 or 10 years for reasons of proper accounting and tax law requirements.</p>
                    <h4>Provision is mandatory or necessary:</h4>
                    <p>The provision of your personal data is voluntary. Without the provision of your personal data, we cannot grant you access to our offered contents and services.</p>
                    <br/>
                    <h3>Contact form</h3>
                    <h4>Nature and purpose of the processing:</h4>
                    <p>The data you enter will be stored for the purpose of individual communication with you. For this purpose, it is necessary to enter a valid e-mail address and your name. This is used to assign the enquiry and to subsequently answer it. The specification of further data is optional.</p>
                    <h4>Legal basis:</h4>
                    <p>The data entered in the contact form is processed on the basis of a legitimate interest (Art. 6 para. 1 letter f DSGVO).</p>
                    <p>By providing the contact form, we would like to make it easy for you to contact us. The information you provide will be stored for the purpose of processing your enquiry and for possible follow-up questions.</p>
                    <p>If you contact us to request an offer, the data entered in the contact form will be processed for the purpose of implementing pre-contractual measures (Art. 6 para. 1 lit. b DSGVO).</p>
                    <h4>Receiver:</h4>
                    <p>Recipients of the data may be contract processors.</p>
                    <h4>Storage duration:</h4>
                    <p>Data will be deleted at the latest 6 months after processing the request.</p>
                    <p>If a contractual relationship is established, we are subject to the legal retention periods according to HGB and delete your data after these periods have expired.</p>
                    <h4>Provision is mandatory or necessary:</h4>
                    <p>The provision of your personal data is voluntary. However, we can only process your request if you provide us with your name, your E-Mail address and the reason for your request.</p>
                    <br/>
                    <h3>SSL Encryption</h3>
                    <p>To protect the security of your data during transmission, we use state-of-the-art encryption procedures (e.g. SSL) via HTTPS.</p>
                    <br/>
                    <h3>Information about your right of objection according to Art. 21 DSGVO</h3>
                    <h4>Right of objection on a case-by-case basis</h4>
                    <p>You have the right to object at any time, for reasons arising from your particular situation, to the processing of personal data relating to you, which is carried out on the basis of Art. 6 paragraph 1 letter f DSGVO (data processing based on a weighing of interests); this also applies to profiling based on this provision within the meaning of Art. 4 No. 4 DSGVO.</p>
                    <p>If you object, we will no longer process your personal data unless we can prove compelling reasons for processing that are worthy of protection and outweigh your interests, rights and freedoms, or unless the processing serves to assert, exercise or defend legal claims.</p>
                    <h4>Recipient of a notice of opposition</h4>
                    <p>E-Mail: support@anihance.com</p>
                    <br/>
                    <h3>Changes to our privacy policy</h3>
                    <p>We reserve the right to adapt this data protection declaration so that it always meets the current legal requirements or to implement changes to our services in the data protection declaration, e.g. when introducing new services. The new data protection declaration then applies to your renewed visit.</p>
                    <br/>
                    <h3>Questions to the Data Protection Officer</h3>
                    <p>If you have any questions about data protection, please write us an e-mail or contact the person responsible for data protection in our organisation directly:</p>
                    <p>E-Mail: moritz.schoepf@anihance.com</p>
                    <p>The data protection declaration was created with the help of <a href="https://www.activemind.de/">activeMind AG</a>, the experts for external data protection officers (version #2020-09-30).</p>
                </Container>
                </div>
            );
        }
    }
  }