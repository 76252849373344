import React from 'react';
import { Container, Table } from "react-bootstrap";
import { locale } from './Locale';
import { Helmet } from "react-helmet";

export class FAQComponent extends React.Component {
    render() {

        let listOfUnsupportedApplications = (
            <div>
            <p>{locale.get("FAQTableDescription")}</p>
            <Table size="sm" striped bordered responsive>
                <thead>
                    <tr>
                        <th>{locale.get("FAQTableUnsupported")}</th>
                        <th>{locale.get("FAQTableAlternative")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Netflix App (Microsoft Store)</td>
                        <td>Netflix Website (Chrome)</td>
                    </tr>
                    <tr>
                        <td>Windows 10 Film & TV</td>
                        <td>VLC Media Player</td>
                    </tr>
                </tbody>
            </Table>
            {locale.get("FAQTableQuestion")}
            </div>
        );

        return (
            <div style={{ display: "flex", justifyContent : "center", alignItems: "center" }}>
            <Helmet>
                <title>Anihance - {locale.get("FAQ")}</title>
                <meta name="description" content={locale.get("FrequentlyAskedQuestions")}/>
            </Helmet>
            <Container fluid="sm" style={{ maxWidth: 900 }}>
                <h2 style={{ textAlign: "center" }}>{locale.get("FrequentlyAskedQuestions")}</h2>
                <br/>
                <QuestionAnswer question={locale.get("FAQBlackVideoQuestion")} answer={locale.get("FAQBlackVideoAnswer")}/>
                <QuestionAnswer question={locale.get("FAQUnsuppordedQuestion")} answer={listOfUnsupportedApplications}/>
                <QuestionAnswer question={locale.get("FAQCartoonQuestion")} answer={locale.get("FAQCartoonAnswer")}/>
                <QuestionAnswer question={locale.get("FAQAdvancedOptionsQuestion")} answer={locale.get("FAQAdvancedOptionsAnswer")}/>
                <QuestionAnswer question={locale.get("FAQNotFullscreenQuestion")} answer={locale.get("FAQNotFullscreenAnswer")}/>
                <QuestionAnswer question={locale.get("FAQPerformanceDropQuestion")} answer={locale.get("FAQPerformanceDropAnswer")}/>
            </Container>
            </div>
        );
    }
}

class QuestionAnswer extends React.Component {
    render() {
        return (
            <div style={{ paddingBottom: 50 }}>
                <h4>{this.props.question}</h4>
                {this.props.answer}
            </div>
        );
    }
}