import React from "react";
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Button, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import { HomeComponent } from "./HomeComponent";
import { RegisterComponent } from "./RegisterComponent";
import { AccountComponent } from "./AccountComponent";
import { FeedbackComponent } from "./FeedbackComponent";
import { MessageComponent } from "./MessageComponent";
import { ResetPasswordComponent } from "./ResetPasswordComponent";
import { locale } from "./Locale";
import { LicenseComponent } from "./LicenseComponent";
import { FAQComponent } from "./FAQComponent";
import { DownloadComponent } from "./DownloadComponent";
import { LegalNoticeComponent } from "./LegalNoticeComponent";
import { PrivacyPolicyComponent } from "./PrivacyPolicyComponent";
import { TranslateComponent } from "./TranslateComponent";

function App() {

  // Navbar should by default show register and login buttons on the right
  let accountManagement =
    <Nav className="mr">
      <div className="m-1"/>
      <Button variant="secondary" href="/register">{locale.get("Register")}</Button>
      <div className="m-1"/>
      <Button variant="primary" href="/account">{locale.get("Login")}</Button>
    </Nav>;

  // Get the account data, might be null when not logged in
  let accountData = sessionStorage.getItem("AccountData");

  // Check if logged in and show username and logout instead
  if (accountData !== null) {
    // String needs to be parsed to JSON object
    accountData = JSON.parse(accountData);

    accountManagement = 
    <Nav className="mr">
      <Nav.Link href="/account">{accountData.username}</Nav.Link>
      <div className="m-1"/>
      <Button variant="secondary" onClick={() => { sessionStorage.clear(); window.location.reload(); }}>{locale.get("Logout")}</Button>
    </Nav>;
  }

  return (
    // This component is used for navigating the website
    // The routes checks which page needs to be rendered (checks top to bottom)
    <BrowserRouter>
      <Navbar bg="light" expand="md">
        <Link to="/">
          <Image src="Logo Black.png" width={150}/>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav>"/>
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="/">{locale.get("About")}</Nav.Link>
          <Nav.Link href="/download">{locale.get("Download")}</Nav.Link>
          <Nav.Link href="/faq">{locale.get("FAQ")}</Nav.Link>
          <Nav.Link href="/feedback">{locale.get("Feedback")}</Nav.Link>
          <NavDropdown title={locale.get("Language")} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => locale.setLanguage(0)}>English</NavDropdown.Item>
            <NavDropdown.Item onClick={() => locale.setLanguage(1)}>Deutsch</NavDropdown.Item>
            <NavDropdown.Item onClick={() => locale.setLanguage(2)}>한국어</NavDropdown.Item>
            <NavDropdown.Item onClick={() => locale.setLanguage(3)}>日本語</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        {accountManagement}
        </Navbar.Collapse>
      </Navbar>
      <div style={{ paddingTop: 100, minHeight: "calc(100vh - 212px)" }}>
      <Routes>
        <Route path="/en" element={<TranslateComponent language={0}/>}/>
        <Route path="/de" element={<TranslateComponent language={1}/>}/>
        <Route path="/kr" element={<TranslateComponent language={2}/>}/>
        <Route path="/jp" element={<TranslateComponent language={3}/>}/>
        <Route path="/download" element={<DownloadComponent/>}/>
        <Route path="/faq" element={<FAQComponent/>}/>
        <Route path="/feedback" element={<FeedbackComponent/>}/>
        <Route path="/register" element={<RegisterComponent/>}/>
        <Route path="/account" element={<AccountComponent/>}/>
        <Route path="/license-agreement" element={<LicenseComponent/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicyComponent/>}/>
        <Route path="/legal-notice" element={<LegalNoticeComponent/>}/>
        <Route path="/register-success" element={<MessageComponent header={locale.get("RegisterSuccess")} message={locale.get("VerifyAccount")} linkLabel={locale.get("DownloadAnihance")} link="/download"/>}/>
        <Route path="/verify-success" element={<MessageComponent header={locale.get("VerifySuccess")} message={locale.get("VerifySuccessNote")}/>}/>
        <Route path="/verify-fail" element={<MessageComponent header={locale.get("VerifyFail")}/>}/>
        <Route path="/reset-password" element={<ResetPasswordComponent/>}/>
        <Route path="/error" element={<MessageComponent header={locale.get("ErrorPageNonexistent")}/>}/>
        <Route path="/" element={<HomeComponent/>}/>
      </Routes>
      </div>
      <div style={{ paddingTop: 100 }}>
      <Navbar bg="light" expand="md">
        <CookieConsent style={{ opacity: 0.75 }} location="bottom" buttonText={locale.get("CookieConsentAgree")}>{locale.get("CookieConsent")}</CookieConsent>
        <Nav className="m-auto">
          <Nav.Link href="/license-agreement" target="_blank">{locale.get("LicenseAgreement")}</Nav.Link>
          <Nav.Link href="/privacy-policy" target="_blank">{locale.get("PrivacyPolicy")}</Nav.Link>
          <Nav.Link href="/legal-notice" target="_blank">{locale.get("LegalNotice")}</Nav.Link>
        </Nav>
      </Navbar>
      </div>
    </BrowserRouter>
  );
}

export default App;
